import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { addNewCourse } from "../../../TimeTables/TimeTable/api";
import { updateConfig } from "../../../../store/slices/Students/studentsProfileSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useGetCourseDataQuery } from "../../../../store/queries/students";
import { getCourseAmount, getCourseModuleList } from "../../../../store/slices/Students/studentsSlice";
import { useSelector } from "react-redux";
import { format, parse } from "date-fns";

const useUpdateNewCourseForm = ({ studentData, courseRefetch }) => {
  
  const profileState = useSelector((state) => state?.studentProfile);
  const dispatch = useDispatch();
  let selectedStudent = studentData?.rows?.Student?.filter(
    (item) => item?.id === studentData?.rows?.student_id
  );
  // useEffect(() => {
  //   if (editCourseData !== "") {
  //     formik.setValues({
  //       course_start_date: editCourseData?.course_start_date ?? "",
  //       weekly_hours: editCourseData?.weekly_hours ?? "",
  //     });
  //   }
  //   //eslint-disable-next-line
  // }, [editCourseData]);
  // const {
  //   data: timeTableData = {},
  //   // isFetching,
  //   // isSuccess,
  //   isLoading,
  //   refetch,
  // } = useGetTimeTableListDataQuery({
  //   faculty: selectedStudent?.[0]?.faculty_id,
  //   length: "",
  //   page: "",
  // });
  const [fieldIndex, setFieldIndex] = useState(0);
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      previous_course_id: studentData?.rows?.Student?.[0]?.course_id,
      course_id: studentData?.rows?.Student?.[0]?.course_id,
      student_id: studentData?.rows?.student_id,
      // currency_code: studentData?.rows?.currency_code
    });
    //eslint-disable-next-line
  }, [studentData?.rows?.student_id]);

  useEffect(() => {
    if (selectedStudent?.[0]?.course_id) {
      dispatch(getCourseModuleList(selectedStudent?.[0]?.course_id));
    }
    //eslint-disable-next-line
  }, [selectedStudent?.[0]?.course_id]);

  const [initialData, setInitialData] = useState({
    previous_course_id: selectedStudent?.[0]?.course_id,
    student_id: studentData?.rows?.student_id,
    course_id: "",
    course_start_date: "",
    weekly_hours: "",
    total_amount: "",
    currency_code: "AED",
    received_amount: "",
    transaction_date: "",
  });
  const { data: courseListData } = useGetCourseDataQuery();

  const courseList = courseListData?.data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const validation = Yup.object({
    course_id: Yup.string().required("Enter Course"),
    course_start_date: Yup.string().required("*Course start date Required"),
    weekly_hours: Yup.string().trim().required("*Weekly hours Required"),
    total_amount: Yup.string().required("*Total Amount Required"),
    received_amount: Yup.string().required("*Received Amount Required"),
    currency_code: Yup.string().required("Please Select Currency code"),
    transaction_date: Yup.string().required("*Received date required"),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      addNewCourse(values).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          courseRefetch();
          toast.success(response?.data?.message);
        } else if (response?.data?.status_code === 422) {
          toast.error(response?.data?.message);
          handleCloseModal();
          
        } else {
          toast.error(response?.message);
          handleCloseModal();
        }
      });
    },
  });

  
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCourseUpdateModal = false;
      })
    );
  };
  
  let courseObj = courseList?.filter(
    (item) => item?.value === formik?.values?.course_id
  );
  
  const reverseDateString = (dateString) => {
    const parts = dateString?.split("-");
    const reversedDateString = parts?.[0] + "-" + parts?.[1] + "-" + parts?.[2];
    return reversedDateString;
  };
  
  let dateStartDateStr = reverseDateString(formik.values.course_start_date);
  const handleStartDateChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("course_start_date", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  const handleCourseChange = (value) => {
    formik.setFieldValue("course_id", value.value);
    dispatch(getCourseAmount({ course_id: value.value })).then((res) => {
      if (res?.payload?.data?.status_code === 200) {
        formik.setFieldValue(
          "total_amount",
          res?.payload?.data?.data?.course_price
        );
        formik.setFieldValue("currency_code", res?.payload?.data?.data?.currency_code);
      } else {
        toast.error(`Something went wrong!`);
      }
    });
  };

  let currencyList = [
    {
    label: "INR",
    value: "INR"
  },
  {
    label: "AED",
    value: "AED"
  },
  ]

  return {
    formik,
    courseList,
    selectedStudent,
    courseObj,
    dateStartDateStr,
    setFieldIndex,
    handleCloseModal,
    handleStartDateChange,
    currencyList,
    profileState,
    handleCourseChange
  };
};

export default useUpdateNewCourseForm;
