import { Table } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useStudentList from "./useStudentList";
import Style from "./studentListForm.module.scss";

const StudentListForm = ({ studentListData, courseName }) => {
  const { newData, getRow } = useStudentList(studentListData);
  return (
    <>
      <div className="pro-p-5">
        <div className="pro-d-flex pro-items-center pro-mb-3 pro-justify-between">
          <h6 className="pro-mb-0 pro-ttl h6">Course Module Attending:</h6>
          <h5 className={`h5 pro-ttl pro-mb-0`}>{courseName}</h5>
        </div>
        <Table
          multiSelect={false}
          data={newData?.data?.rows?.data?.data || []}
          uniqueID={"id"}
          fields={newData?.field}
          showCheckBox={false}
          getRow={getRow}
          editable={false}
          deletable={false}
          assignable={false}
          extraClasssName={Style.table}
        />
      </div>
    </>
  );
};

export default StudentListForm;
