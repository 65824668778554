import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  activeFilter: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  scrollStatusPage: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  is_edit: false,
  streamData: {},
  sourceData: {},
  userData: {},
  selectedItemsDetails: {},
  selectedItemData: {},
  showCreateModal: false,
  showNotesCk: false,
  viewNotes: false,
  showMailCk: false,
  initial: true,
  showTransferModal: false,
  transferNote: "",
  IDs: {},
};

export const getEnquiryDataByID = createAsyncThunk(
  "enquiry/show",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/enquiry/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStreams = createAsyncThunk(
  "/v1/stream",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/stream`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSource = createAsyncThunk(
  "/v1/enquiry/source",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/enquiry/source`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getUsers = createAsyncThunk(
  "/v1/users",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/enquiry/list-user-sales`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteEnquiry = createAsyncThunk(
  "/v1/enquiry/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/enquiry/delete/${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEnquiry = createAsyncThunk(
  "/v1/enquiry/update",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/enquiry/update/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createEnquiry = createAsyncThunk(
  "/v1/enquiry/store",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/enquiry/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEnquiryScrollListData = createAsyncThunk(
  "enquiry/getEnquiryScrollListData",
  async (params, { rejectWithValue }) => {
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const api = await getAxiosInstance();
    try {
      if (params?.initial) {
        const response = await api.get(
          `/v1/enquiry/list-all?${getParams("start_date")}${getParams(
            "end_date"
          )}${getParams("filter")}${getParams("status")}${getParams(
            "sort_by"
          )}sort_order=${params?.sort_order || "desc"}&length=${
            params?.length || "10"
          }&${getParams("search")}page=${params?.page || 1}`
        );
        return response;
      } else {
        const response = await api.get(
          `/v1/enquiry?${getParams("start_date")}${getParams(
            "end_date"
          )}${getParams("filter")}${getParams("status")}${getParams(
            "sort_by"
          )}sort_order=${params?.sort_order || "desc"}&length=${
            params?.length || "10"
          }&${getParams("search")}page=${params?.page || 1}`
        );
        return response;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEnquiryScrollListData.pending, (state, action) => {
      state.queryStatus = "pending";
    });
    builder.addCase(getEnquiryScrollListData.fulfilled, (state, action) => {
      if (state.queryData?.data && !action.meta?.arg?.clearState) {
        if (action?.payload?.data?.data?.rows?.enquiries?.data) {
          //remove duplicating
          const newData = action?.payload?.data?.data?.rows?.enquiries?.data;

          const uniqueData = newData?.filter((newItem) => {
            return !state.queryData?.data?.some((existingItem) => {
              // Compare based on a unique identifier, for example, 'id'
              return newItem?.id === existingItem?.id;
            });
          });

          state.queryData = {
            ...action?.payload?.data?.data?.rows?.enquiries,
            queryStatus: "fulfilled",
            data: [
              ...state.queryData?.data,
              ...uniqueData,
              // ...action?.payload?.data?.data?.rows?.enquiries?.data,
            ],
          };
        } else {
          state.queryData = {
            ...action?.payload?.data?.data?.rows?.enquiries,
            queryStatus: "fulfilled",
            data: [...state.queryData?.data],
          };
        }
      } else {
        state.queryData = {
          ...action?.payload?.data?.data?.rows?.enquiries,
          queryStatus: "fulfilled",
          page: 1,
        };
      }
      state.queryStatus = "fulfilled";
    });
    builder.addCase(getEnquiryScrollListData.rejected, (state, action) => {
      state.queryStatus = "failed";
    });
    builder
      .addCase(getEnquiryDataByID.pending, (state) => {
        state.isLoading = true;
        state.selectedItemsDetails = {};
        state.error = null;
      })
      .addCase(getEnquiryDataByID.fulfilled, (state, action) => {
        state.selectedItemsDetails = action.payload.data.data;
      })
      .addCase(getEnquiryDataByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getStreams.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getStreams.fulfilled, (state, action) => {
        state.streamData = action.payload.data.data;
      })
      .addCase(getStreams.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getSource.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSource.fulfilled, (state, action) => {
        state.sourceData = action.payload.data.data;
      })
      .addCase(getSource.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.userData = action.payload.data.data;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = enquirySlice.actions;

export default enquirySlice.reducer;
