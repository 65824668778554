import { getAxiosInstance } from "../../../api";

export const getTimeSlotList = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/v1/time-table/time-slots`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
// export const getDaysList = async () => {
//   const api = await getAxiosInstance();
//   try {
//     const response = await api.get(`/v1/time-table/days`);
//     return response;
//   } catch (error) {
//     return error?.response?.data;
//   }
// };
// export const getFacultyList = async () => {
//   const api = await getAxiosInstance();
//   try {
//     const response = await api.get(`/v1/faculty/fetch-all-faculty`);
//     return response;
//   } catch (error) {
//     return error?.response?.data;
//   }
// };
export const getCourseList = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/v1/time-table/assigned-courses/${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const storeTimeSchedule = async (params) => {
  
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/v1/time-table/store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const storeAssignFaculty = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/v1/student/update-course-faculty`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addNewCourse = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/v1/student/assign-new-course/${params?.student_id}`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const deleteStudent = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/v1/time-table/delete/${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
