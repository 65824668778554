import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useTimeTableMenu from "./useTimeTableMenu";

const TimeTables = () => {
  const { drawerMenu } = useTimeTableMenu();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default TimeTables;
