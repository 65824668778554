import { useMemo, useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  // deleteEnquiry,
  getEnquiryDataByID,
  // getEnquiryScrollListData,
  updateConfig,
} from "../../../store/slices/Enroll/enrollSlice";
import {
  useGetEnrollListDataQuery,
  useUpdateEnquiryStaffAssignDataMutation,
  useGetEnquiryStatusListDataQuery,
  useUpdateEnrollListFieldsDataMutation,
  useUpdateEnquiryListStatusMutation,
  useRemainderStatusMutation,
  useNotedStatusMutation,
  useCreateNoteMutation,
  useSendMailMutation,
} from "../../../store/queries/enquires";
// import Style from "./enrolled.module.scss";
// import { EnquirySelectWithIcons } from "../Enrolled/EnquirySelectWithIcons";
// import { toast } from "react-toastify";
import moment from "moment";
import { useGetSalesTeamDataQuery } from "../../../store/queries/students";
// import { SelectWithIcons } from "../../Global/SelectWithIcons";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const useEnrolled = (dashboard) => {
  const refMenu = useRef(null);
  const refStaffMenu = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.enroll);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [staffData, setStaffData] = useState({});
  const [selectedItemID, setSelectedItemID] = useState("");
  const [isMultiSelected, setIsMultiSelected] = useState([]);
  const currentLoginId = localStorage.getItem("USER_DETAILS");
  const [formattedStaffs, setFormattedStaffs] = useState([]);
  // const [selectedItemData, setSelectedItemData] = useState("");
  const [skip, setSkip] = useState(true);

  const { showCreateModal, enquiryId, activeFilter, selectedItemsDetails } =
    useSelector((state) => state.enroll);

  // const checkTop = (id) =>
  //   refMenu[id]?.getBoundingClientRect().top > window.innerHeight / 2 + 50;
  // const checkStaffTop = (id) =>
  //   refStaffMenu[id]?.getBoundingClientRect().top > window.innerHeight / 2 + 50;

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [formattedStatus, setFormattedStatus] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");

  const debouncedSearchTerm = useDebounce(state.search, 1000);

  const {
    data: enquiry = {},
    isFetching,
    // isSuccess,
    isLoading,
    refetch,
  } = useGetEnrollListDataQuery({
    sort_by: state.sortBy,
    sort_order: state.sortOrder,
    search: debouncedSearchTerm,
    start_date: getFormatedDate(state.startDate),
    end_date: getFormatedDate(state.endDate),
    length: state.currentPageSize,
    page: state.currentPage,
  });

  const {
    data: enquiryStatus = {},

    refetch: refetchStatus,
  } = useGetEnquiryStatusListDataQuery({
    search: debouncedSearchTerm,
    start_date: getFormatedDate(state.startDate),
    end_date: getFormatedDate(state.endDate),
  });

  useEffect(() => {
    var temp = [];
    enquiryStatus?.data?.enquiry_status?.map((element) => {
      temp.push({
        value: element.statusType,
        label: element.title,
      });
      return element;
    });
    setFormattedStatus(temp);
  }, [enquiryStatus]);

  const { data: saleTeamData } = useGetSalesTeamDataQuery();

  useEffect(() => {
    var temp = [];
    saleTeamData?.data?.rows?.map((element) => {
      temp.push({
        value: element?.id,
        label: element?.full_name,
        icon: (
          <img
            src={element?.thumbnail_url}
            alt={""}
            width={"500"}
            height={"600"}
          ></img>
        ),
      });
      return element;
    });
    setFormattedStaffs(temp);
  }, [saleTeamData]);

  const notificationOptions = [
    {
      label:
        moment().add(20, "minutes").fromNow().replace(/^i/, "I") +
        " (" +
        moment().add(20, "minutes").format("h:mm A") +
        ")",
      value: 20,
    },
    {
      label:
        moment().add(1, "hour").fromNow().replace(/^i/, "I") +
        " (" +
        moment().add(1, "hour").format("h:mm A") +
        ")",
      value: 60,
    },
    {
      label:
        moment().add(3, "hour").fromNow().replace(/^i/, "I") +
        " (" +
        moment().add(3, "hour").format("h:mm A") +
        ")",
      value: 180,
    },
    {
      label: "Tomorrow (" + moment().add(1, "day").format("h:mm A") + ")",
      value: 24,
    },
    {
      label: "Next week (" + moment().add(1, "week").format("h:mm A") + ")",
      value: 168,
    },
  ];

  // useEffect(() => {
  //   if (
  //     Object.keys(state?.queryData ?? {})?.length === 0
  //     //  && activeFilter === 2
  //   ) {
  //     dispatch(
  //       getEnquiryScrollListData({
  //         sort_by: state.sortBy,
  //         sort_order: state.sortOrder,
  //         search: state.search,
  //         start_date: getFormatedDate(state.startDate),
  //         end_date: getFormatedDate(state.endDate),
  //         // length: 30,
  //         page: state.scrollPage,
  //         initial: true,
  //       })
  //     );
  //   }

  //   //eslint-disable-next-line
  // }, [activeFilter]);

  useEffect(() => {
    enquiryStatus?.data?.enquiry_status?.forEach((statusObject) => {
      dispatch(
        updateConfig((state) => {
          state.scrollStatusPage[
            `statusType${statusObject.statusType}Page`
          ] = 1;
        })
      );
    });
  }, [enquiryStatus?.data?.enquiry_status]);

  // useEffect(() => {
  //   if (activeFilter === 2) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.queryData = {};
  //       })
  //     );

  //     dispatch(
  //       getEnquiryScrollListData({
  //         sort_by: state.sortBy,
  //         sort_order: state.sortOrder,
  //         search: debouncedSearchTerm,
  //         start_date: getFormatedDate(state.startDate),
  //         end_date: getFormatedDate(state.endDate),
  //         // length: 10,
  //         page: state.scrollPage,
  //         initial: true,
  //       })
  //     );
  //   }

  //   //eslint-disable-next-line
  // }, [debouncedSearchTerm]);

  // useEffect(() => {
  //   if (activeFilter === 2) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.queryData = {};
  //       })
  //     );

  //     dispatch(
  //       getEnquiryScrollListData({
  //         sort_by: state.sortBy,
  //         sort_order: state.sortOrder,
  //         search: state.search,
  //         start_date: getFormatedDate(state.startDate),
  //         end_date: getFormatedDate(state.endDate),
  //         // length: 10,
  //         page: state.scrollPage,
  //         initial: true,
  //       })
  //     );
  //   }

  //   //eslint-disable-next-line
  // }, [state.startDate, state.endDate]);

  // const handleLoadMore = useCallback(
  //   (status) => {
  //     if (state?.queryStatus === "fulfilled") {
  //       dispatch(
  //         getEnquiryScrollListData({
  //           sort_by: state.sortBy,
  //           sort_order: state.sortOrder,
  //           search: state.search,
  //           status: status,
  //           start_date: getFormatedDate(state.startDate),
  //           end_date: getFormatedDate(state.endDate),
  //           length: 10,
  //           // page: state?.queryData?.current_page + 1,
  //           page: state.scrollStatusPage[`statusType${status}Page`] + 1,
  //         })
  //       );
  //       dispatch(
  //         updateConfig((state) => {
  //           state.scrollStatusPage[`statusType${status}Page`] =
  //             state.scrollStatusPage[`statusType${status}Page`] + 1;
  //         })
  //       );
  //     }
  //     //eslint-disable-next-line
  //   },
  //   [dispatch, state]
  // );

  useEffect(() => {
    if (state.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [state.clearSelection]);

  // const { data: enquiryViewData = {} } = useGetEnquiryViewDataQuery(
  //   {
  //     digital_enquiry_id: selectedId,
  //     page: 1,
  //   },
  //   { skip }
  // );

  // const { data: optionsData = {} } = useGetMemberCreationFormDataQuery({});

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: state.startDate,
    endDate: state.endDate,
    key: "selection",
  });
  // const [updateStaffAssign] = useUpdateEnquiryStaffAssignDataMutation();
  const [notedStatus] = useNotedStatusMutation();
  const [updateEnquiryFields] = useUpdateEnrollListFieldsDataMutation();
  const [updateEnquiryStatus] = useUpdateEnquiryListStatusMutation();
  const [remainderUpdateStatus] = useRemainderStatusMutation();
  const [createNote, { isLoading: noteIsLoading }] = useCreateNoteMutation();
  const [sendMail, { isLoading: mailIsLoading }] = useSendMailMutation();

  const hasCreatePermission = useMemo(() => {
    let permission = enquiry?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [enquiry]);
  const hasEditPermission = useMemo(() => {
    let permission = enquiry?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [enquiry]);
  // const hasViewPermission = useMemo(() => {
  //   let permission = enquiry?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_view")
  //   );
  //   return permission?.[0]?.can_view ?? 0;
  // }, [enquiry]);
  const hasDeletePermission = useMemo(() => {
    let permission = enquiry?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [enquiry]);

  const handleSort = (label) => {
    if (state.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = state.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.queryData = {};
      })
    );
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleTableFilter = (activeFilter) => {
    handleHideTooltip();
    activeFilter === 1
      ? dispatch(
          updateConfig((state) => {
            state.activeFilter = 2;
          })
        )
      : dispatch(
          updateConfig((state) => {
            state.activeFilter = 1;
          })
        );
  };

  // const handleCreateClick = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showCreateModal = true;
  //       state.selectedItemsDetails = "";
  //     })
  //   );
  // };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        // state.is_edit = false;
      })
    );
  };

  const handleEditAction = (data) => {
    setSkip(() => false);
    setSelectedId(data?.[0]);
    dispatch(getEnquiryDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  // const handleZindexClick = (id) => {
  //   enquiry?.data?.rows?.enquiries?.data?.map((_, index) => {
  //     // refMenu[index].parentNode.style.zIndex = 0;
  //     if (refMenu[index]?.parentNode) {
  //       refMenu[index].parentNode.style.zIndex = 5;
  //     }
  //   });
  //   refMenu[id].parentNode.style.zIndex = 9;
  // };

  // const handleZindexClick = (id) => {
  //   enquiry?.data?.rows?.enquiries?.data?.map((item) => {
  //     if (refMenu[item.id]) {
  //       refMenu[item.id].parentNode.style.zIndex = item.id === id ? 9 : 5;
  //     }
  //   });
  // };

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({});

  // const handleViewTooltip = (e, data) => {
  //   setStaffData(data?.assigned_to);
  //   setShowTooltip(true);
  //   const pageWidth = window.innerWidth / 1.5;
  //   const pageHeight = window.innerHeight / 1.5;
  //   const currectElement = e?.currentTarget?.getBoundingClientRect();
  //   const mousePostionX = currectElement?.left;
  //   const mousePostionY = currectElement?.top;
  //   setTooltipPosition({
  //     x: mousePostionX,
  //     y: mousePostionY,
  //     placementY: mousePostionY <= pageHeight ? "bottom" : "top",
  //     placementX: mousePostionX <= pageWidth ? "left" : "right",
  //   });
  // };

  const handleHideTooltip = () => {
    setTooltipPosition({});
    setShowTooltip(false);
    setStaffData({});
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => {
        switch (data?.status) {
          case 1:
            return "New Enquiry";
          case 2:
            return "Brochure sent";
          case 3:
            return "Demo Arranged";
          case 4:
            return "Follow up";
          case 5:
            return "Price Factor";
          case 6:
            return "Enrolled";
          case 7:
            return "Lost";
          default:
            return "Not Prospective";
        }
      },
      // assigned_to_full_name: (feild, data) => (
      //   <div
      //     className={`pro-mb-0 ${Style.user_select}`}
      //     ref={(ref) => (refStaffMenu[data?.id] = ref)}
      //   >
      //     {" "}
      //     {/* {(
      //       data?.staff?.name === "Unassigned")
      //     ? ( */}
      //     <SelectWithIcons
      //       menuPlacement={checkStaffTop(data?.id) ? "top" : "bottom"}
      //       menuPosition="auto"
      //       options={formattedStaffs?.filter(
      //         (item) =>
      //           item?.value !== data?.assigned_to?.id &&
      //           item?.value !== currentLoginId
      //       )}
      //       multiSelect={false}
      //       onChange={(staff) => handleStaffChange(staff, data)}
      //       name="select_staff"
      //       placeholder="Unassigned"
      //       value={data?.assigned_to}
      //       handleViewTooltip={handleViewTooltip}
      //       handleHideTooltip={handleHideTooltip}
      //       data={data}
      //     />
      //     {/* ) : (
      //       <>{data?.staff?.name ?? "Assign staff"}</>
      //     )} */}
      //   </div>
      // ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  // const handleStaffChange = (staff, data) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.IDs = { enquiryID: data?.id, staffID: staff?.value };
  //     })
  //   );

  //   dispatch(
  //     updateConfig((state) => {
  //       state.showTransferModal = true;
  //       state.transferNote = "";
  //     })
  //   );
  // };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleTransferClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showTransferModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date();
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/enquiry");
  };

  // const handleStatusChange = (item, data) => {
  //   const obj = {
  //     status: parseInt(item?.value),
  //     email_scenario_id: 2,
  //     id: parseInt(data?.id),
  //   };

  //   updateEnquiryStatus(obj).then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       // toast.success("Successfully updated status");
  //       if (activeFilter === 1) {
  //         refetch?.();
  //         refetchStatus?.();
  //       }
  //     } else {
  //       toast.error("Failed to update status");
  //     }
  //   });
  // };
  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  // const handleDeleteAction = async () => {
  //   if (Array.isArray(selectedItemID) && showDeleteConfirm) {
  //     dispatch(deleteEnquiry(selectedItemID[0]))
  //       .unwrap()
  //       .then((result) => {
  //         if (result?.message === "Success") {
  //           toast.success(result?.message);
  //           dispatch(
  //             updateConfig((state) => {
  //               state.clearSelection = true;
  //             })
  //           );
  //         } else {
  //           toast.error(result?.message);
  //         }
  //         if (result) {
  //           refetch();
  //           refetchStatus?.();
  //           setSelectedItemID("");
  //           setShowDeleteConfirm(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setShowDeleteConfirm(false);
  //       });
  //   } else {
  //     setShowDeleteConfirm(false);
  //   }
  // };

  // const handleAddNote = (data) => {
  //   handleCloseNotes?.();

  //   dispatch(
  //     updateConfig((state) => {
  //       state.showNotesCk = true;
  //       state.selectedItemData = data;
  //     })
  //   );
  // };

  const handleSendMail = (data) => {
    // dispatch(
    //   globalUpdateConfig((state) => {
    //     state.searchKey = "";
    //     state.searchData = {};
    //   })
    // );
    // dispatch(
    //   updateConfig((state) => {
    //     state.showMailCk = true;
    //     state.selectedItemData = data;
    //   })
    // );
    setSkip(() => false);
    setSelectedId(data?.[0]);
    dispatch(getEnquiryDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };

  // const handleCloseMailCk = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showMailCk = false;
  //       state.clearSelection = true;
  //     })
  //   );
  // };

  // const handleRemainderUpdate = (id, value) => {
  //   remainderUpdateStatus({
  //     remainder: value,
  //     note_id: id,
  //   }).then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       toast.success(response?.data?.message);
  //     } else {
  //       toast.error(response?.data?.message);
  //     }
  //   });
  // };

  // const updateNoteIsNotedStatus = (data, noteId, status) => {
  //   const newData = state?.queryData?.data?.map((item) => {
  //     if (item.id === data?.id) {
  //       // If the IDs match, update the notes array for this item
  //       const updatedNotes = item.notes.map((note) => {
  //         if (note.id === noteId) {
  //           // If the note IDs match, update the is_noted status for this note
  //           return { ...note, is_noted: status };
  //         }
  //         return note; // If the note IDs don't match, keep the note unchanged
  //       });

  //       return { ...item, notes: updatedNotes };
  //     }
  //     return item; // If the IDs don't match, keep the item unchanged
  //   });

  //   const updatedData = {
  //     ...data,
  //     notes: data.notes.map((note) => {
  //       if (note.id === noteId) {
  //         return {
  //           ...note,
  //           is_noted: status,
  //         };
  //       }
  //       return note;
  //     }),
  //   };

  //   dispatch(
  //     updateConfig((state) => {
  //       state.queryData.data = newData;
  //     })
  //   );

  //   return updatedData;
  // };

  // const handleIsnoted = (status, id) => {
  //   const updatedData = updateNoteIsNotedStatus(
  //     state.selectedItemData,
  //     id,
  //     +!status
  //   );
  //   notedStatus({
  //     is_noted: +!status,
  //     note_id: id,
  //   }).then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       // toast.success(response?.data?.message);
  //       dispatch(
  //         updateConfig((state) => {
  //           state.selectedItemData = updatedData;
  //         })
  //       );
  //     } else {
  //       toast.error(response?.data?.message);
  //     }
  //   });
  // };

  // const handleMailCkResult = (data, file, userIds) => {
  //   const obj = {
  //     // subject: data,
  //     // user_ids: isMultiSelected,
  //     enquiry_ids: isMultiSelected,
  //     email_scenario_id: "1",
  //   };

  //   if (file?.[0]?.name) {
  //     obj.file = file?.[0];
  //   }

  //   // let formData = new FormData();

  //   // Object.keys(obj).forEach((key) => {
  //   //   if (key === "enquiry_ids") {
  //   //     formData.append(key, JSON.stringify(obj[key]));
  //   //   } else {
  //   //     formData.append(key, obj[key]);
  //   //   }
  //   // });

  //   sendMail(obj).then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       handleCloseMailCk();
  //       dispatch(
  //         updateConfig((state) => {
  //           state.clearSelection = true;
  //         })
  //       );
  //       toast.success(response?.data?.message);
  //     } else {
  //       toast.error(response?.data?.message);
  //     }
  //   });
  // };

  // const handleViewNote = (data) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.viewNotes = true;
  //       state.selectedItemData = data;
  //     })
  //   );
  // };

  // const handleCloseNotesCk = (close = true) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showNotesCk = false;
  //     })
  //   );
  //   if (state.selectedItemData?.notes?.length > 0 && close) {
  //     handleViewNote?.(state.selectedItemData);
  //   }
  // };

  // const handleCloseNotes = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.viewNotes = false;
  //     })
  //   );
  // };

  // const handleNotesCkResult = (data, file, notify, user_id) => {
  //   const obj = {
  //     enquiry_id: state.selectedItemData?.id,
  //     note: data,
  //     reminder_after: notify,
  //     parent_id: "",
  //     user_id: user_id,
  //   };
  //   if (file?.[0]?.name) {
  //     obj.file = file?.[0];
  //   }

  //   let formData = new FormData();

  //   Object.keys(obj).forEach((key) => {
  //     formData.append(key, obj[key]);
  //   });

  //   createNote(formData).then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       handleCloseNotesCk(false);
  //       refetch?.();
  //       const newData = state?.queryData?.data?.map((item) => {
  //         if (item.id === obj?.enquiry_id) {
  //           // If the IDs match, update the notes array for this item
  //           const updatedNotes = [...item.notes, response?.data?.data];

  //           return { ...item, notes: [...updatedNotes] };
  //         }
  //         return item; // If the IDs don't match, keep the item unchanged
  //       });

  //       dispatch(
  //         updateConfig((state) => {
  //           state.queryData.data = newData;
  //         })
  //       );

  //       toast.success(response?.data?.message);
  //     } else {
  //       toast.error(response?.data?.message);
  //     }
  //   });
  // };
  const handleSelectedIds = (ids) => {
    setIsMultiSelected(ids);
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    enquiry,
    enquiryStatus,
    state,
    currentPage: state.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    date,
    staffData,
    activeFilter,
    // optionsData,
    showEditModal,
    tableFields,
    paginationOptions,
    enquiryId,
    actionOptions,
    setIsMultiSelected,
    // enquiryViewData,
    isMultiSelected,
    showImageModal,
    imageData,
    hasEditPermission,
    hasDeletePermission,
    noteIsLoading,
    mailIsLoading,
    showTooltip,
    tooltipPosition,
    handleTransferClose,
    handleDateRangeChange,
    // handleIsnoted,
    // handleViewNote,
    // handleAddNote,
    // handleCloseNotes,
    notificationOptions,
    selectedItemsDetails,
    handleSelectedIds,
    // handleRemainderUpdate,
    // handleLoadMore,
    handleSendMail,
    // handleCloseMailCk,
    // showTablePopup,
    // tableRef,
    // handleMailCkResult,
    // handleCloseNotesCk,
    // handleNotesCkResult,
    handleDelete,
    handleTableFilter,
    updateEnquiryStatus,
    handleEditAction,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    refetchStatus,
    updateEnquiryFields,
    handleSort,
    handleSearch,
    // handleCreateClick,
    closeModal,
    getRow,
    showDeleteConfirm,
    setShowDeleteConfirm,
    // handleDeleteAction,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
  };
};

export default useEnrolled;
