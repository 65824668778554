import {  createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  transferNote: "",
  IDs: {},
  selectedItemsDetails: "",
};

const actionLogsSlice = createSlice({
  name: "actionLogs",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  
});

export const { updateConfig } = actionLogsSlice.actions;

export default actionLogsSlice.reducer;
