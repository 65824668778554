import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useBranches = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Branches",
      link: "/branches",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/branches", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.modules?.flatMap?.(
      (menu) => [
        // menu.module_name,
        ...menu?.sub_menu_permissions?.map(
          (side_menu) => side_menu?.sub_menu_name
        ),
        // ...menu.permissions?.map((side_menu) => side_menu.name),
      ]
    );
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useBranches;
