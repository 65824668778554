import React from "react";
import Style from "./timeTableDay.module.scss";

const TimeTableDay = ({ isVertical, day }) => {
  return isVertical ? (
    <div className={`${Style.root} ${Style.vertical}`}>
      <h6 className={`pro-mb-0 ${Style.title}`}>{day}</h6>
    </div>
  ) : (
    <div className={Style.root_empty}></div>
  );
};

export default TimeTableDay;
