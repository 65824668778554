import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";

import {
  createEnquiry,
  getSource,
  getStreams,
  getUsers,
  updateConfig,
  updateEnquiry,
} from "../../../../store/slices/Enquiries/enquirySlice";
import countryData from "../../../../utils/components/countryCode";
import { phoneRegExp } from "../../../../utils/functions/table";

const useEnquiryForm = ({
  refetch,
  refetchStatus,
  closeModal,
  showCreateModal,
}) => {
  const dispatch = useDispatch();

  const {
    selectedItemsDetails,
    userData,
    streamData,
    sourceData,
    clearSelection,
  } = useSelector((state) => state.enquiry);

  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.cover_photo?.url : ""
  );

  useEffect(() => {
    dispatch(getStreams());
    dispatch(getUsers());
    dispatch(getSource());
    // eslint-disable-next-line
  }, []);

  let YupValidationObject = {
    name: Yup.string().required("*Required"),

    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*Phone number Required"),
    email: Yup.string().nullable().email("Invalid email"),
    message: Yup.string().max(255, "Max 255 character allowed").nullable(),
    stream_id: Yup.string().nullable(),
    source_id: Yup.string().required("*Source Required"),
    assigned_to: Yup.string().required("*Required"),
  };
  useEffect(() => {
    if (clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [clearSelection]);

  const cuntryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  let createEnquiryVal = Yup.object(YupValidationObject);

  const formik = useFormik({
    initialValues: {
      name: selectedItemsDetails !== "" ? selectedItemsDetails?.name : "",

      phone_number:
        selectedItemsDetails !== "" ? selectedItemsDetails?.phone_number : "",
      dial_code:
        selectedItemsDetails !== "" ? selectedItemsDetails?.dial_code : "+91",
      email: selectedItemsDetails !== "" ? selectedItemsDetails?.email : "",

      message: selectedItemsDetails !== "" ? selectedItemsDetails?.message : "",
      stream_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.stream_id : "",
      source_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.source_id : "",
      assigned_to:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.assigned_to?.id
          : "",
    },
    validationSchema: createEnquiryVal,
    enableReinitialize: true,

    onSubmit: async (values, { resetForm }) => {
      if (selectedItemsDetails !== "") {
        dispatch(
          updateEnquiry({ id: selectedItemsDetails.id, data: values })
        ).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            closeModal?.();
            refetch();
            refetchStatus?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createEnquiry(values)).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            refetch();
            refetchStatus?.();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };
  const getFieldError = (fieldName) => {
    if (formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }

    return "";
  };

  return {
    formik,
    userData,
    streamData,
    sourceData,
    cuntryCodeOptions,
    getFieldError,
    selectedItemsDetails,

    handleCloseModal,
  };
};

export default useEnquiryForm;
