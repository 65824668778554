import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
  // Button,
} from "@wac-ui-dashboard/wac_component_library";
import useStudentsListing from "./useStudentsListing";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";
import Style from "./studentsListing.module.scss";
import StudentsForm from "./StudentsForm";
import { FaSort } from "react-icons/fa";
import { ImageCard } from "../../Global/ImageCard";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import Select from "react-select";
import Filter from "./Filter";
// import TimeTable from "../../Global/TimeTable";

const StudentsListing = ({ dashboard = false }) => {
  const {
    queryData,
    enquiryViewData,
    handleEditAction,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    showCreateModal,
    hasCreatePermission,
    handleFilterAction,
    isFetching,
    isLoading,
    // activeFilter,
    showEditModal,
    // tableFields,
    state,
    currentPage,
    paginationOptions,
    imageData,
    // actionOptions,
    // hasExportPermission,
    showImageModal,
    // showTablePopup,
    // tableRef,
    hasDeletePermission,
    hasEditPermission,
    // hasUpdatePermission,
    optionsData,
    // tableStructure,
    handleDateRangeChange,
    closeImageModal,
    // handleDashboardRedirect,
    // handleTableFilter,
    handleDelete,
    refetch,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    handlePagination,
    closeEditModal,
    handleDateChange,
    handlePageSize,
    updateStudentsFields,
    handleClearClick,
    handleChange,
    handleExcelDownload,
    date,
    selectedOption,
    options,
    // selectedStatusOption,
    // handleStatusChange,
    // statusOptions,
  } = useStudentsListing(dashboard);
  const propertiesToInclude = ["name", "status"];

  return (
    <>
      {!dashboard ? (
        <>
          <HeadingGroup
            title={"Students"}
            buttonTitle={hasCreatePermission ?? false ? "Add new" : ""}
            className={`pro-mb-4`}
            handleClick={handleCreateClick}
          >
            <button
              className={`pro-btn pro-btn-outline-primary pro-items-center pro-ms-3`}
              type="submit"
              onClick={handleExcelDownload}
            >
              <span className="material-symbols-outlined x4">download</span>
              <span>{`Export Data`}</span>
            </button>
          </HeadingGroup>
        </>
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row gx-2">
          <div className={`${isFetching ? "col" : "col-auto"}`}>
            {!dashboard ? (
              <SearchFilters
                // showDateRange
                dropDownFilter={
                  <>
                    <div className="col-auto">
                      <CustomDateRangePicker
                        defaultDateRange={date}
                        handleDateRangeChange={handleDateRangeChange}
                      />
                    </div>
                    <div className="col-auto dropdown-filter">
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                        placeholder="Filter by Course End Date"
                        classNamePrefix={`pro-input`}
                        className={`pro-input lg search-filter-select`}
                      />
                    </div>
                  </>
                }
                onDateChange={handleDateChange}
                onSearchInput={handleSearch}
                showActions={true}
                handleActionClick={handleEditClick}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                handleClear={handleClearClick}
                initialDateRange={date}
                searchInputProps={{ value: state?.search }}
              />
            ) : (
              <></>
            )}
          </div>
          {!isFetching && (
            <div className="col-auto">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn}`}
                onClick={handleFilterAction}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div>
          )}
        </div>

        {queryData?.data?.rows?.students?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={queryData?.data?.rows?.students?.data || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={hasEditPermission ? true : false}
            deletable={hasDeletePermission ? true : false}
            handleEdit={handleEditAction}
            assignable={false}
            clear={state.clearSelection}
            multiSelect={false}
            handleDelete={handleDelete}
            editable={hasDeletePermission ? true : false}
            fields={queryData?.data?.field}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={state?.currentPageSize}
            extraClasssName={Style.table}
            // ref={tableRef}
          />
        )}
        {/* <TimeTable /> */}

        {queryData?.data?.rows?.students?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={queryData?.data?.rows?.students?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === state?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          title={state?.is_edit ? "Update Students" : "Add Students"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <StudentsForm
            refetch={refetch}
            isStickyFooter
            edit={state?.is_edit}
            showCreateModal={showCreateModal}
            optionsData={optionsData}
            closeModal={closeModal}
            enquiryViewData={enquiryViewData}
            hasEditPermission={hasEditPermission}
          />
        </OffCanvasLayout>
        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              // tableFields={queryData?.data?.field}
              tableFields={Object.keys(queryData?.data?.field ?? {})
                .filter((property) => !propertiesToInclude.includes(property))
                .reduce((filteredObj, property) => {
                  filteredObj[property] = queryData?.data?.field[property];
                  return filteredObj;
                }, {})}
              moduleId={queryData?.data?.table_id}
              updateData={updateStudentsFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
        <OffCanvasLayout
          show={state.filterShow}
          handleClose={handleFilterAction}
          title={`Apply Filter`}
          backdrop="static"
        >
          <Filter />
        </OffCanvasLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default StudentsListing;
