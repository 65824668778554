import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Login from "../components/Auth/Login";
import ResetPassword from "../components/Auth/ResetPassword";

import Settings from "../components/Configure";
import Roles from "../components/Configure/Roles";
import ManageRoles from "../components/Configure/Roles/ManageRoles";

import Appearance from "../components/Configure/Appearance";
import useTheme from "../components/Configure/Appearance/useTheme";

import CaptureEmail from "../components/Auth/CaptureEmailForm";
import Enquiries from "../components/Enquires";
import Enquiry from "../components/Enquires/Enquiry";
import Branches from "../components/Branches";
import Branch from "../components/Branches/branch";
import Courses from "../components/Courses";
import Course from "../components/Courses/course";
import Faculties from "../components/Faculties";
import Faculty from "../components/Faculties/Faculties";
import Sales from "../components/Sales";
import Sale from "../components/Sales/Sales";

import Invoice from "../components/Invoices/invoice";

import SubAdmin from "../components/SubAdmin/subAdmin";

import ActionLog from "../components/Configure/ActionLog";
import Students from "../components/Students";
import StudentsListing from "../components/Students/StudentsListing";
import StudentsDetailLayout from "../components/Students/StudentsDetailLayout";
import StudentDetails from "../components/Students/StudentDetails";
import Email from "../components/Configure/Email";
import Notification from "../components/Notification";
import NotificationListing from "../components/Notification/NotificationListing";
import Dashboard from "../components/Dashboard";
import DashboardListing from "../components/Dashboard/DashboardListing";
import Enroll from "../components/Enroll";
import Enrolled from "../components/Enroll/Enrolled";
import Invoices from "../components/Invoices";
import TimeTable from "../components/TimeTables/TimeTable";
import TimeTables from "../components/TimeTables";

const Routes = () => {
  useTheme("light");

  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="notifications" element={<Notification />}>
          <Route path="" element={<NotificationListing />} />
        </Route>
        <Route path="/" element={<Index />} />
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
        </Route>
        <Route path="enquires" element={<Enquiries />}>
          <Route path="" element={<Enquiry />}></Route>
        </Route>
        <Route path="enroll" element={<Enroll />}>
          <Route path="" element={<Enrolled />}></Route>
        </Route>
        <Route path="students" element={<Students />}>
          <Route path="" element={<StudentsListing />}></Route>
        </Route>

        <Route path="students-profile" element={<StudentsDetailLayout />}>
          <Route path=":studentID" element={<StudentDetails />}></Route>
        </Route>
        {/* <Route path="branches" element={<Branches />}>
          <Route path="" element={<Branch />}></Route>
        </Route> */}

        <Route path="courses" element={<Courses />}>
          <Route path="" element={<Course />}></Route>
        </Route>
        <Route path="faculties" element={<Faculties />}>
          <Route path="" element={<Faculty />}></Route>
        </Route>
        <Route path="time-table" element={<TimeTables />}>
          <Route path="" element={<TimeTable />}></Route>
        </Route>
        <Route path="sales" element={<Sales />}>
          <Route path="" element={<Sale />}></Route>
        </Route>

        <Route path="payments" element={<Invoices />}>
          <Route path="" element={<Invoice />}></Route>
        </Route>

        {/* <Route path="sub-admins" element={<SubAdmins />}>
          <Route path="" element={<SubAdmin />}></Route>
        </Route>
        <Route path="action-logs" element={<ActionLogs />}>
          <Route path="" element={<ActionLog />}></Route>
        </Route> */}

        <Route path="settings" element={<Settings />}>
          <Route path="roles" element={<Roles />}></Route>
          <Route path="roles/permission" element={<ManageRoles />}></Route>
          <Route path="appearance" element={<Appearance />}></Route>
          <Route path="action-logs" element={<ActionLog />}></Route>
          {/* <Route path="invoices" element={<Invoice />}></Route> */}
          <Route path="sub-admins" element={<SubAdmin />}></Route>
          <Route path="branches" element={<Branch />}></Route>
          <Route path="email" element={<Email />}></Route>
        </Route>
        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route
            path="forgot-password/capture-email"
            element={<CaptureEmail />}
          ></Route>
          <Route
            path="forgot-password/:token"
            element={<ForgotPassword />}
          ></Route>
          <Route
            path="reset-password/:token"
            element={<ResetPassword />}
          ></Route>
        </Route>

        <Route path={"/*"} element={<Navigate to={"/"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
