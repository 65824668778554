import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import Style from "./enquiryForm.module.scss";
import {
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import useCouseForm from "./useCourseForm";
import AddCourse from "../ImageCard";

const CourseForm = ({
  refetch,
  optionsData,
  showCreateModal,
  hasEditPermission,
  closeModal,
  isStickyFooter,
}) => {
  const {
    formik,
    getFieldError,
    addState,
    branchData,
    profilefileInputRef,
    handleCoverImage,
    handleCloseModal,
    userData,
    imageCoverPreview,
    streamData,
    handleRemoveField,
    handleAddItemClick,
    selectedItemsDetails,
    currencyList,

    isLoading,
  } = useCouseForm({ refetch, closeModal, showCreateModal });

  return (
    <div>
      <Input
        label={"Name*"}
        id={`name`}
        name={`name`}
        type="text"
        {...formik.getFieldProps(`name`)}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        className={`pro-input lg ${
          formik.touched.name && formik.errors.name && " error"
        }`}
        error={formik.touched.name && formik.errors.name}
        errorMessage={formik.errors.name}
      />

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Branch*
        </label>
        <Select
          id="branch_id"
          name="branch_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.branch_id && formik.touched.branch_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={branchData?.data}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.user?.full_name}
          value={branchData?.data?.filter(
            (m) => formik.values.branch_id === m?.id
          )}
          onChange={(value) => formik.setFieldValue("branch_id", value.id)}
        />
        {formik.touched.branch_id && formik.errors.branch_id && (
          <span className="error-text">{formik.errors.branch_id}</span>
        )}
      </div>
      <Input
        label={"Duration*"}
        id={`duration`}
        placeholder={"In Hours"}
        name={`duration`}
        type="text"
        {...formik.getFieldProps(`duration`)}
        className={`pro-input lg ${
          formik.touched.duration && formik.errors.duration && " error"
        }`}
        error={formik.touched.duration && formik.errors.duration}
        errorMessage={formik.errors.duration}
      />
      {/* <Input
        label={"Weekly Hours*"}
        id={`weekly_hours`}
        name={`weekly_hours`}
        type="text"
        {...formik.getFieldProps(`weekly_hours`)}
        className={`pro-input lg ${
          formik.touched.weekly_hours && formik.errors.weekly_hours && " error"
        }`}
        error={formik.touched.weekly_hours && formik.errors.weekly_hours}
        errorMessage={formik.errors.weekly_hours}
      /> */}
      <Input
        label={"Description*"}
        id={`description`}
        name={`description`}
        type="text"
        {...formik.getFieldProps(`description`)}
        className={`pro-input lg ${
          formik.touched.description && formik.errors.description && " error"
        }`}
        error={formik.touched.description && formik.errors.description}
        errorMessage={formik.errors.description}
      />
      <div className="col-md-12">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Currency *
              </label>
              <Select
                name="currency"
                options={currencyList} // replace with your currency options
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                value={currencyList?.filter((m) => formik?.values?.currency_code === m?.value)}
                onChange={(selectedOption) =>
                  formik.setFieldValue(`currency_code`, selectedOption?.value)
                }
                className={`pro-input lg ${
                  formik.touched?.currency_code &&
                  formik.errors?.currency_code &&
                " error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur(`currency_code`)}
                // value={formik.values.currency_code}
                
              />
              {formik.touched?.currency_code &&
                formik.errors?.currency_code && (
                <div className="error-text">{formik.errors?.currency_code}</div>
              )}
            </div>
          </div>
          
      <Input
        label={"Course Price*"}
        id={`course_price`}
        name={`course_price`}
        type="text"
        {...formik.getFieldProps(`course_price`)}
        className={`pro-input lg ${
          formik.touched.course_price && formik.errors.course_price && " error"
        }`}
        error={formik.touched.course_price && formik.errors.course_price}
        errorMessage={formik.errors.course_price}
      />
      
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Stream*
        </label>
        <Select
          id="stream_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.stream_id && formik.errors.stream_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={streamData?.rows}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={streamData?.rows?.filter(
            (m) => formik.values.stream_id === m?.id
          )}
          onBlur={formik.handleBlur("stream_id")}
          onChange={(value) => {
            formik.setFieldValue("stream_id", value?.id || null);
          }}
        />
        {formik.touched.stream_id && formik.errors.stream_id && (
          <span className="error-text">{formik.errors.stream_id}</span>
        )}{" "}
      </div>
      <AddCourse
        formik={formik}
        addState={addState}
        handleRemoveField={handleRemoveField}
      />
      <Button
        className={`pro-btn-primary lg pro-w-100 pro-my-4`}
        onClick={handleAddItemClick}
      >
        {` Add Another`}
      </Button>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleCoverImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.image?.name !== undefined
                  ? limitStrLength(formik?.values?.image?.name, 30)
                  : selectedItemsDetails !== "" &&
                    selectedItemsDetails?.original_image_name
                  ? selectedItemsDetails?.original_image_name
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`course - 01`}
          />
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-pt-3  pro-mt-5 ${
          isStickyFooter && "offcanvas-footer-sticky-btn"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={closeModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CourseForm;
