import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useSubAdmins from "./useSubAdmins";

const SubAdmins = () => {
  const { drawerMenu } = useSubAdmins();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default SubAdmins;
