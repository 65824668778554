import { combineReducers } from "@reduxjs/toolkit";
import { settings } from "./queries/settings";
import roleSlice from "./slices/Settings/roleSlice";
import globalSlice from "./slices/Global";
import notificationSlice from "./slices/Notifications/notificationSlice";
import enquirySlice from "./slices/Enquiries/enquirySlice";
import { enquires } from "./queries/enquires";
import { branches } from "./queries/branches";
import branchesSlice from "./slices/Branches/branchesSlice";
import { courses } from "./queries/courses";
import coursesSlice from "./slices/Courses/coursesSlice";
import { faculties } from "./queries/faculties";
import facultiesSlice from "./slices/Faculties/facultiesSlice";
import salesSlice from "./slices/Sales/salesSlice";
import { sales } from "./queries/sales";
import { invoices } from "./queries/invoices";
import invoicesSlice from "./slices/Invoices/invoicesSlice";
import { subAdmins } from "./queries/subAdmins";
import subAdminsSlice from "./slices/SubAdmins/subAdminsSlice";
import { actionLogs } from "./queries/actionLogs";
import actionLogsSlice from "./slices/ActionLogs/actionLogsSlice";
import { students } from "./queries/students";
import studentsSlice from "./slices/Students/studentsSlice";
import { email } from "./queries/email";
import emailSlice from "./slices/Email/emailSlice";
import studentsProfileSlice from "./slices/Students/studentsProfileSlice";
import { notificationRtk } from "./queries/notifications";
import { dashboard } from "./queries/Dashboard";
import enrollSlice from "./slices/Enroll/enrollSlice";
import { timeTable } from "./queries/timeTable";
import timeTableSlice from "./slices/TimeTable/timeTableSlice";
import studentFilterSlice from "./slices/Students/studentFilterSlice";

const appReducer = combineReducers({
  [enquires.reducerPath]: enquires.reducer,
  [students.reducerPath]: students.reducer,
  [branches.reducerPath]: branches.reducer,
  [settings.reducerPath]: settings.reducer,
  [courses.reducerPath]: courses.reducer,
  [faculties.reducerPath]: faculties.reducer,
  [sales.reducerPath]: sales.reducer,
  [actionLogs.reducerPath]: actionLogs.reducer,
  [invoices.reducerPath]: invoices.reducer,
  [subAdmins.reducerPath]: subAdmins.reducer,
  [email.reducerPath]: email.reducer,
  [notificationRtk.reducerPath]: notificationRtk.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [timeTable.reducerPath]: timeTable.reducer,
  enquiry: enquirySlice,
  email: emailSlice,
  students: studentsSlice,
  studentProfile: studentsProfileSlice,
  actionLogs: actionLogsSlice,
  branches: branchesSlice,
  faculties: facultiesSlice,
  sales: salesSlice,
  courses: coursesSlice,
  invoices: invoicesSlice,
  subAdmins: subAdminsSlice,
  role: roleSlice,
  global: globalSlice,
  notification: notificationSlice,
  enroll: enrollSlice,
  timetable: timeTableSlice,
  studentsFilter: studentFilterSlice,
});

export default appReducer;
