import Style from "./NotificationListing.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import useNotificationListing from "./useNotificationListing";
import Assets from "../../../assets/Assets";
import NotificationListingShimmer from "../../Global/Shimmers/NotificationListingShimmer";

const NotificationListing = () => {
  const {
    state,
    showScroll,
    notificationState,
    handleRedirect,
    lastElement,
    handleScrollToTop,
  } = useNotificationListing();

  const parse = require("html-react-parser");

  return (
    <>
      <div className={`${Style.wrapper} pro-py-5`}>
        {state?.notificationStatus === "pending" && !notificationState?.data ? (
          <>
            <div className={`${Style.root} pro-pt-2`}>
              <div className={`${Style.list_wrap} pro-p-5`}>
                {[...Array(10)].map((_, i) => (
                  <NotificationListingShimmer key={i} />
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            {showScroll && (
              <button
                className={`scoll-top`}
                onClick={() => handleScrollToTop()}
              >
                <span className="material-symbols-outlined">expand_less</span>
              </button>
            )}

            <div className={`${Style.root} pro-pt-2`}>
              <div className={`${Style.list_wrap} pro-p-5`}>
                {notificationState?.data?.map((item, index) => {
                  return (
                    <div
                      className={`${Style.notification_item} pro-d-flex ${
                        item?.read_status === 1 ? Style.un_read : ""
                      }`}
                      // onClick={() =>
                      //   handleRedirect(
                      //     item?.redirection_type,
                      //     item?._id,
                      //     item?.redirection_id
                      //   )
                      // }
                      key={index}
                      ref={
                        index === notificationState?.data?.length - 5
                          ? lastElement
                          : false || null
                      }
                    >
                      <div className={`${Style.img_wrap} pro-me-2`}>
                        <Image
                          width={53}
                          height={53}
                          alt={`img`}
                          src={`${
                            item?.images?.[0]?.url ?? Assets.GENDER_IMAGE
                          }`}
                        />
                      </div>
                      <p className="pro-mb-0 pro-d-flex pro-gap-1 pro-flex-wrap pro-notification-wrap">
                        {parse(item?.notification_message)}
                      </p>
                      {/* <span className={`${Style.date} pro-ms-auto pro-font-xs`}>
                        {item?.received_on}
                      </span> */}
                    </div>
                  );
                })}

                {state?.notificationStatus === "pending" &&
                notificationState?.data ? (
                  <>
                    {[...Array(10)].map((_, i) => (
                      <NotificationListingShimmer key={i} />
                    ))}
                  </>
                ) : (
                  <></>
                )}
                {notificationState?.data?.length < 1 && (
                  <div
                    className={`${Style.no_item_msg} pro-d-flex pro-flex-column pro-text-center pro-py-6`}
                  >
                    <span
                      className={`${Style.icon} pro-mx-auto pro-rounded-circle pro-d-flex pro-justify-center`}
                    >
                      <span className="material-symbols-outlined x4">
                        {" "}
                        notifications{" "}
                      </span>
                    </span>
                    <h6 className="pro-ttl pro-mb-0 pro-mt-5">
                      No notification right now
                    </h6>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NotificationListing;
