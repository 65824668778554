import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("filter")}${getParams("sort_by")}sort_order=${
          params?.sort_order || "desc"
        }&length=${params?.length || "10"}&${getParams("search")}page=${
          params?.page || 1
        }&${getParams("assigned_to")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const enquires = createApi({
  reducerPath: "enquiresApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["enquiry", "enquiryStatus", "enroll", "faculties"],
  endpoints: (builder) => ({
    // Deleted Accounts

    getEnquiryListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/enquiry`,
      }),
      providesTags: ["enquiry"],
    }),

    getEnquiryStatusListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/enquiry/enquiry-status`,
      }),
      providesTags: ["enquiryStatus"],
    }),

    updateEnquiryListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/table-permission-create`,
      }),

      invalidatesTags: ["enquiry"],
    }),
    //enroll section starts
    updateEnrollListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/table-permission-create`,
      }),

      invalidatesTags: ["enroll"],
    }),
    updateTimeTableListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/table-permission-create`,
      }),

      invalidatesTags: ["faculties"],
    }),
    getEnrollListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/enquiry/enrolled-enquiries`,
      }),
      providesTags: ["enroll"],
    }),

    //enroll section ends
    updateEnquiryStaffAssignData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/staffs/assign`,
      }),
      invalidatesTags: ["enquiry"],
    }),

    createEnquiry: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/enquiry/store`,
      }),
      invalidatesTags: ["enquiry"],
    }),

    updateEnquiry: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/enquiry/update/${"id"}`,
      }),
      invalidatesTags: ["enquiry"],
    }),
    updateEnquiryListStatus: builder.mutation({
      query: (body, id) => ({
        method: "post",
        body,
        endpoint: `/v1/enquiry/update-status/${body?.id}`,
      }),
    }),
    createNote: builder.mutation({
      query: (body, id) => ({
        method: "post",
        body,
        endpoint: `/v1/note/store`,
      }),
      invalidatesTags: ["enquiry"],
    }),

    notedStatus: builder.mutation({
      query: (body, id) => ({
        method: "post",
        body,
        endpoint: `/v1/note/update-note-read-status/${body.note_id}`,
      }),
      invalidatesTags: ["enquiry"],
    }),

    remainderStatus: builder.mutation({
      query: (body, id) => ({
        method: "post",
        body,
        endpoint: `/v1/notification/change-read-status/${body.note_id}`,
      }),
      invalidatesTags: ["enquiry"],
    }),

    sendMail: builder.mutation({
      query: (body, id) => ({
        method: "post",
        body,
        endpoint: `/v1/email/send-welcome-mail`,
      }),
    }),
    updateTransferData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/enquiry/update-assigned-to/${body?.enquiry_id}`,
      }),
    }),
  }),
});

export const {
  useGetEnquiryListDataQuery,
  useGetEnrollListDataQuery,
  useUpdateEnquiryStaffAssignDataMutation,
  useGetEnquiryStatusListDataQuery,
  useUpdateEnquiryListFieldsDataMutation,
  useUpdateEnrollListFieldsDataMutation,
  useUpdateTimeTableListFieldsDataMutation,
  useCreateEnquiryMutation,
  useNotedStatusMutation,
  useUpdateEnquiryMutation,
  useUpdateEnquiryListStatusMutation,
  useCreateNoteMutation,
  useSendMailMutation,
  useUpdateTransferDataMutation,
  useRemainderStatusMutation,
} = enquires;
