import React from "react";
import Style from "./notification.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import NotificationListingShimmer from "../Shimmers/NotificationListingShimmer";

const Notification = ({
  data,
  isFetching,
  isNewNotification,
  // handleRedirect,
  handleNotificationRedirect,
  handleReadNotification,
}) => {
  const parse = require("html-react-parser");

  let filteredNotificationData = data
    ?.filter((item) => item.read_status === 0)
    .slice(0, 3);

  return (
    <Dropdown className="pro-me-4">
      <Dropdown.Toggle id="notification-dropdown">
        <div
          className={`${Style.icon_wrap} ${
            isNewNotification ? Style.active : ""
          }`}
        >
          <span
            className="material-symbols-outlined x4"
            onClick={() => handleReadNotification(isNewNotification)}
          >
            {" "}
            notifications{" "}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={Style.dropdown}>
        {filteredNotificationData?.map((item, i) => {
          return (
            <>
              {isFetching ? (
                <>
                  <NotificationListingShimmer />
                  <NotificationListingShimmer />
                </>
              ) : (
                <Dropdown.Item
                  className={Style.notification_wrap}
                  // onClick={() =>
                  //   handleRedirect(
                  //     item?.redirection_type,
                  //     item?._id,
                  //     item?.redirection_id
                  //   )
                  // }
                  key={i}
                >
                  <div className={`${Style.notification_item} pro-d-flex`}>
                    <div
                      className={`${Style.img_wrap} pro-align-self-center pro-me-2`}
                    >
                      <Image
                        width={53}
                        height={53}
                        alt={`img`}
                        src={`${item?.images?.[0]?.url ?? Assets.GENDER_IMAGE}`}
                      />
                    </div>
                    <div
                      className={`${Style.content} pro-align-self-center pro-d-flex pro-flex-column`}
                    >
                      <p
                        className={`pro-mb-1 pro-d-flex pro-gap-1 pro-flex-wrap pro-notification-wrap`}
                      >
                        {parse(item?.notification_message)}
                      </p>
                      {/* <span className={Style.date}>{item?.received_on}</span> */}
                    </div>
                  </div>
                </Dropdown.Item>
              )}
            </>
          );
        })}

        {!isNewNotification && filteredNotificationData?.length === 0 ? (
          <div
            className={`${Style.no_item_msg} pro-d-flex pro-flex-column pro-text-center pro-py-6`}
          >
            <span
              className={`${Style.icon} pro-mx-auto pro-rounded-circle pro-d-flex pro-justify-center`}
            >
              <span className="material-symbols-outlined x4">
                {" "}
                notifications{" "}
              </span>
            </span>
            <h6 className="pro-ttl pro-mb-0 pro-mt-5">
              No notification right now
            </h6>
          </div>
        ) : (
          <></>
        )}
        {data?.length > 0 ? (
          <p
            className={`pro-mb-0 pro-font-sm pro-text-center pro-pb-2 pro-pt-3 ${Style.btn}`}
            onClick={() => handleNotificationRedirect()}
          >
            {isNewNotification ? "View more" : "View all notifications"}
          </p>
        ) : (
          <></>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Notification;
