import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";

const Notification = () => {
  return (
    <CommonLayout dashboard={true} isGrey>
      <Outlet />
    </CommonLayout>
  );
};

export default Notification;
