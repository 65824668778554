import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTranfserForm from "./useAddPaymentForm";
import Select from "react-select";

const AddPaymentForm = ({ refetch }) => {
  const { handleClose, formik, currencyList } = useTranfserForm({
    refetch,
  });

  return (
    <div>
      <div className="row">
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <Input
              id="receivedAmount"
              name="receivedAmount"
              label="Received Amount*"
              className={`pro-input lg ${
                formik.touched.receivedAmount &&
                formik.errors.receivedAmount &&
                "error"
              }`}
              error={
                formik.touched.receivedAmount && formik.errors.receivedAmount
              }
              errorMessage={formik.errors.receivedAmount}
              {...formik.getFieldProps("receivedAmount")}
            />
          </div>
          <div className="col-md-12">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Currency *
              </label>
              <Select
                name="currency"
                options={currencyList} // replace with your currency options
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                value={currencyList?.filter((m) => formik?.values?.currency_code === m?.value)}
                onChange={(selectedOption) =>
                  formik.setFieldValue(`currency_code`, selectedOption?.value)
                }
                className={`pro-input lg ${
                  formik.touched?.currency_code &&
                  formik.errors?.currency_code &&
                " error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur(`currency_code`)}
                // value={formik.values.currency_code}
                
              />
              {formik.touched?.currency_code &&
                formik.errors?.currency_code && (
                <div className="error-text">{formik.errors?.currency_code}</div>
              )}
            </div>
          </div>
          <div className="input-wrap">
            <Input
              id="transaction_date"
              name="transaction_date"
              label="Received Date*"
              type="date"
              className={`pro-input lg ${
                formik.touched.transaction_date &&
                formik.errors.transaction_date &&
                "error"
              }`}
              error={
                formik.touched.transaction_date &&
                formik.errors.transaction_date
              }
              errorMessage={formik.errors.transaction_date}
              {...formik.getFieldProps("transaction_date")}
            />
          </div>
        </div>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik?.handleSubmit}
        >
          {true ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Add Payment
        </Button>
      </div>
    </div>
  );
};

export default AddPaymentForm;
