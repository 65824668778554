import { Dropdown } from "react-bootstrap";
import Style from "./viewNotes.module.scss";
import parse from "html-react-parser";
import { useState } from "react";
const ViewNotes = ({
  handleAddNote,
  handleRemainderUpdate,
  selectedItemData,
  handleIsnoted,
  notificationOptions,
}) => {
  const parse = require("html-react-parser");

  return (
    <div className={Style.root}>
      <div
        onClick={() => handleAddNote(selectedItemData)}
        className={`${Style.add_btn} pro-px-4 pro-py-3 pro-rounded-3`}
      >
        Add new Note
      </div>
      {selectedItemData?.notes?.map((item) => {
        return (
          <div className={`pro-mt-4 ${Style.note_card} pro-rounded-3`}>
            <div className={`${Style.card_top} pro-py-4 pro-px-5`}>
              <p className={`pro-mb-0`}>{parse(item?.note)}</p>
              <span className={`${Style.date} pro-font-xs pro-d-inline-block`}>
                {item?.expected_reminder_time}
              </span>
            </div>
            <div
              className={`pro-py-2 pro-px-5 pro-d-flex  ${Style.card_footer}`}
            >
              <div
                className={`pro-d-flex pro-gap-2 pro-items-center cursor-pointer ${
                  Style.action_btn
                } ${item?.is_noted ? Style.active : ""}`}
                onClick={() => handleIsnoted(item?.is_noted, item?.id)}
              >
                {item?.is_noted ? (
                  <svg width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16 20H6V7L13 0L14.25 1.25C14.3667 1.36667 14.4625 1.525 14.5375 1.725C14.6125 1.925 14.65 2.11667 14.65 2.3V2.65L13.55 7H19C19.5333 7 20 7.2 20.4 7.6C20.8 8 21 8.46667 21 9V11C21 11.1167 20.9875 11.2417 20.9625 11.375C20.9375 11.5083 20.9 11.6333 20.85 11.75L17.85 18.8C17.7 19.1333 17.45 19.4167 17.1 19.65C16.75 19.8833 16.3833 20 16 20ZM4 7V20H0V7H4Z"
                      fill="#1C1B1F"
                    />
                  </svg>
                ) : (
                  <span className="material-symbols-outlined x3">thumb_up</span>
                )}
                <span className="pro-font-sm">Noted</span>
              </div>
              {/* <div className={`pro-d-flex pro-gap-2 pro-items-center cursor-pointer ${Style.action_btn} ${item?.is_noted ? Style.active : ""}`}>
                <span className="material-symbols-outlined x3">chat</span>
                <span className="pro-font-sm">Reply</span>
              </div> */}
              {/* <Dropdown
                className={`${Style.dropdown} pro-ms-auto`}
                drop="start"
              >
                <Dropdown.Toggle
                  id="profile-dropdown"
                  className={`${Style.dropdown_btn} pro-d-flex`}
                >
                  <span className="material-symbols-outlined x3">
                    notifications
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className={Style.dropdown_menu}>
                  <Dropdown.ItemText
                    className={`pro-fw-normal pro-font-xs pro-pb-3 ${Style.item_text}`}
                  >
                    Remind me about this
                  </Dropdown.ItemText>
                  {notificationOptions?.map((notification) => {
                    return (
                      <Dropdown.Item
                        className={`pro-d-flex pro-items-center`}
                        onClick={() =>
                          handleRemainderUpdate(item?.id, notification?.value)
                        }
                      >
                        <span className={`${Style.dropdown_text}`}>
                          {notification?.label}
                        </span>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ViewNotes;
