import { useDispatch, useSelector } from "react-redux";

import {
  useGetStudentDetailsDataQuery,
  useGetStudentDetailsReportDataQuery,
  useGetProfilePaymentListDataQuery,
  useGetProfileCourseListDataQuery,
  useUpdateCourseStatusMutation,
} from "../../../store/queries/students";
import { useParams } from "react-router-dom";
import {
  updateConfig,
  getCourseScrollListData,
  getEditCourseData,
  deleteCourse,
} from "../../../store/slices/Students/studentsProfileSlice";
import Style from "./studentDetails.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EnquirySelectWithIcons } from "../../Enquires/EnquirySelectWithIcons";
import { toast } from "react-toastify";

const useStudentDetails = () => {
  const refMenu = useRef(null);
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state?.studentProfile);
  const { selectedId, isButtonFalse } = useSelector((state) => state?.studentProfile);
  const [formattedStatus, setFormattedStatus] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const { studentID } = useParams();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const [updateCourseStatus] = useUpdateCourseStatusMutation();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: profileCourseList = {},
    isFetching: isCourseFetching,
    refetch: courseRefetch,
  } = useGetProfileCourseListDataQuery({
    student_id: activeProfile,
    page: profileState.courseCurrentPage,
    sort_by: profileState.courseSortBy,
    sort_order: profileState.courseSortOrder,
    page_size: profileState.courseCurrentPageSize,
  });

  const {
    data: profilePaymentList = {},
    isFetching: isPaymentFetching,
    refetch: paymentRefetch,
  } = useGetProfilePaymentListDataQuery({
    student_id: activeProfile,
    page: profileState.paymentCurrentPage,
    sort_by: profileState.paymentSortBy,
    sort_order: profileState.paymentSortOrder,
    page_size: profileState.paymentCurrentPageSize,
  });

  useEffect(() => {
    if (
      Object.keys(profileState?.queryData ?? {})?.length === 0
      //  && profileState.activeFilter === 2
    ) {
      dispatch(
        getCourseScrollListData({
          student_id: activeProfile,
          sort_by: profileState.courseSortBy,
          sort_order: profileState.courseSortOrder,
          length: 30,
          page: profileState.courseCurrentPage,
          initial: true,
        })
      );
    }

    //eslint-disable-next-line
  }, [profileState.activeFilter]);

  useEffect(() => {
    profileCourseList?.data?.rows?.status?.forEach((statusObject) => {
      dispatch(
        updateConfig((state) => {
          state.scrollStatusPage[
            `statusType${statusObject.statusType}Page`
          ] = 1;
        })
      );
    });
    //eslint-disable-next-line
  }, [profileCourseList?.data?.rows?.status]);

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.queryData = {};
        state.activeFilter = 1;
      })
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    var temp = [];
    profileCourseList?.data?.rows?.courses[0]?.status?.map((element) => {
      temp.push({
        value: element.statusType,
        label: element.title,
      });
      return element;
    });
    setFormattedStatus(temp);
  }, [profileCourseList]);

  useEffect(() => {
    if (profileState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [profileState.clearSelection]);

  const {
    data: profileData,
    // refetch,
  } = useGetStudentDetailsDataQuery({
    student_id: activeProfile,
  });

  const { data: ReportData, refetch: refetchDataContainer } =
    useGetStudentDetailsReportDataQuery({
      student_id: activeProfile,
    });

  const headerLabel = {
    course_enrolled: "Courses enrolled",
    percentage_complete: "Percent complete",
    current_stage: "Current stage",
  };

  const HeaderValue = {
    course_enrolled: `${ReportData?.data?.course_count ?? "0"}`,
    percentage_complete: `${ReportData?.data?.percentageComplete ?? "0"}%`,
    current_stage: `${ReportData?.data?.level ?? ""}`,
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCourseUpdateModal = true;
      })
    );
  };
  const handleUpdateNewCourseClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCourseUpdateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCourseCreateModal = false;

        // state.selectedItemsDetails = ""
      })
    );
  };
  
  const hasAssignPermission = useMemo(() => {
      let permission = profileCourseList?.data?.permissions?.filter((p) =>
        Object.keys(p).includes("can_delete")
      );
      return permission?.[0]?.can_delete ?? false;
  }, [profileCourseList]);

  // const hasUpdatePermission = useMemo(() => {
  //   let permission = profileData?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_create")
  //   );
  //   return permission?.[0]?.can_create ?? 0;
  // }, [profileData]);

  const handlePaymentPagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.paymentCurrentPage = page;
      })
    );
  };

  const handleCoursePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.courseCurrentPage = page;
      })
    );
  };

  const handlePaymentPageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.paymentCurrentPageSize = page_size;
        state.paymentCurrentPage = 1;
      })
    );
  };

  const handleCoursePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.courseCurrentPageSize = page_size;
        state.courseCurrentPage = 1;
      })
    );
  };

  const handleTableFilter = (activeFilter) => {
    activeFilter === 1
      ? dispatch(
          updateConfig((state) => {
            state.activeFilter = 2;
          })
        )
      : dispatch(
          updateConfig((state) => {
            state.activeFilter = 1;
          })
        );
  };

  const handlePaymentSort = (label) => {
    if (profileState.paymentSortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.paymentCurrentPage = 1;
          state.paymentSortOrder =
            profileState.paymentSortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.paymentCurrentPage = 1;
          state.paymentSortBy = label;
          state.paymentSortOrder = "asc";
        })
      );
    }
  };
  const handleCourseSort = (label) => {
    if (profileState.courseSortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.courseCurrentPage = 1;
          state.courseSortOrder =
            profileState.courseSortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.courseCurrentPage = 1;
          state.courseSortBy = label;
          state.courseSortOrder = "asc";
        })
      );
    }
  };

  const getCourseRow = (feild, data) => {
    const rows = {

      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => (
        <div
          className={`
          ${Style.color_dropdown}
          ${
            data?.status === 0
              ? Style.pending
              : data?.status === 1
              ? Style.in_progress
              : data?.status === 2
              ? Style.completed
              : data?.status === 3
              ? Style.course_lagging
              : data?.status === 4
              ? Style.take_break
              : ""
          }
          `}
          ref={(ref) => (refMenu[data?.id] = ref)}
        >
          {/* {(
            data?.staff?.name === "Unassigned") &&
          data?.status !== "Deleted" &&
          hasAssignPermission === 1 ? ( */}
          <EnquirySelectWithIcons
            // menuPlacement={checkTop(data?.id) ? "top" : "bottom"}
            menuPlacement={"bottom"}
            menuPosition="fixed"
            options={formattedStatus}
            multiSelect={false}
            onChange={(e) => handleStatusChange(e, data)}
            name="select_staff"
            noteIcon={false}
            // notes={data?.notes?.length}
            placeholder="Change Status"
            // handleViewTooltip={handleViewTooltip}
            // handleHideTooltip={handleHideTooltip}
            data={data}
          />
          {/* // ) : (
          //   <>{data?.staff?.name ?? "Assign staff"}</>
          // )}  */}
        </div>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const getPaymentRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleStatusChange = (item, data) => {
    const obj = {
      status: parseInt(item?.value),
      id: parseInt(data?.id),
    };

    updateCourseStatus(obj).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Successfully updated status");
        courseRefetch()
      } else {
        toast.error(response?.data?.message);
      }
    });
  };

  const handleLoadMore = useCallback(
    (status) => {
      if (profileState?.queryStatus === "fulfilled") {
        dispatch(
          getCourseScrollListData({
            student_id: activeProfile,
            sort_by: profileState.courseSortBy,
            sort_order: profileState.courseSortOrder,
            status: status,
            length: 10,
            page: profileState.scrollStatusPage[`statusType${status}Page`] + 1,
          })
        );

        dispatch(
          updateConfig((state) => {
            state.scrollStatusPage[`statusType${status}Page`] =
              state.scrollStatusPage[`statusType${status}Page`] + 1;
          })
        );
      }
      //eslint-disable-next-line
    },
    //eslint-disable-next-line
    [dispatch, profileState]
  );
  const handleAssignAction = (e, value) => {

    dispatch(
      updateConfig((state) => {
        state.showAssignModal = !state.showAssignModal;
        state.selectedId = e?.[0];
        state.course = value?.id
      })
    );
  };
  const handleEditAction = (e, value) => {
    
    let id = e?.[0];
    let selectedData = value?.modules?.filter(
      (item) => item?.id === id
    );
    if (selectedData?.[0]?.status === 0) {
      toast.info(`Course status in pending is not editable`);
    } else {
      dispatch(
        updateConfig((state) => {
          state.showEditModal = !state.showEditModal;
          state.editSelectedId = e?.[0];
        })
      );
      if (e?.[0]) {
        dispatch(getEditCourseData(e?.[0]));
      }
    }
  };

  const handleNewCourseEditAction = () => {
      dispatch(
        updateConfig((state) => {
          state.showCourseUpdateModal = !state.showCourseUpdateModal;
        })
      );
      
  };

  const handleDelete = async (id) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(id);
  };

  const handleDeleteAction = async () => {
    if (selectedItemID && showDeleteConfirm) {
      let params = {
        "student_id": activeProfile,
        "course_id": selectedItemID
      }
      dispatch(deleteCourse(params))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            courseRefetch();
            paymentRefetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleAssignFaculty = (e, value) => {
    let id = e?.[0];
      dispatch(
        updateConfig((state) => {
          state.showAssignFacultyModal = !state.showAssignFacultyModal;
          state.editSelectedId = e?.[0];
          state.selectedFacultyData = value
        })
      );
    
  };


  return {
    profilePaymentList,
    profileCourseList,
    profileState,
    closeModal,
    selectedId,
    paginationOptions,
    isCourseFetching,
    isPaymentFetching,
    headerDetails,
    handleTableFilter,
    handleLoadMore,
    handleAssignAction,
    updateCourseStatus,
    handleCreateClick,
    handlePaymentPagination,
    handleCoursePagination,
    handlePaymentPageSize,
    handleCoursePageSize,
    handlePaymentSort,
    handleCourseSort,
    getCourseRow,
    getPaymentRow,
    handleEditAction,
    refetchDataContainer,
    courseRefetch,
    isButtonFalse,
    handleUpdateNewCourseClick,
    handleNewCourseEditAction,
    handleDelete,
    handleDeleteAction,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleAssignFaculty,
    hasAssignPermission
  };
};

export default useStudentDetails;
