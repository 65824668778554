import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  activeFilter: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  scrollStatusPage: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  is_edit: false,
  //   streamData: {},
  //   userData: {},
  selectedItemsDetails: {},
  selectedItemData: {},
  showCreateModal: false,
  //   showNotesCk: false,
  //   viewNotes: false,
  //   showMailCk: false,
  initial: true,
  //   showTransferModal: false,
  //   transferNote: "",
  IDs: {},
};

export const getEnquiryDataByID = createAsyncThunk(
  "enquiry/show",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/enquiry/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const enquirySlice = createSlice({
  name: "enroll",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnquiryDataByID.pending, (state) => {
        state.isLoading = true;
        state.selectedItemsDetails = {};
        state.error = null;
      })
      .addCase(getEnquiryDataByID.fulfilled, (state, action) => {
        state.selectedItemsDetails = action.payload.data.data;
      })
      .addCase(getEnquiryDataByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = enquirySlice.actions;

export default enquirySlice.reducer;
