import React from "react";
import Style from "./selectWithIcons.module.scss";
import Select, { components } from "react-select";


export const EnquirySelectWithIcons = ({
  options,
  onChange,
  multiSelect,
  label,
  data,
  dashboard,
  handleViewTooltip,
  handleHideTooltip,
  handleAddNote,
  handleViewNote,
  noteIcon = true,
  notes = 0,
  noteDate = "",
  menuPosition,
  menuPlacement,
  onMenuOpen,
  ...rest
}) => {
  // const customMultiValueLabel = (option) => {
  //   return (
  //     <components.MultiValueLabel {...option}>
  //       <div
  //         className={`pro-d-flex ${Style.avatar_container} avatar-container`}
  //         onMouseLeave={!dashboard ? () => handleHideTooltip() : () => {}}
  //       >
  //         <div
  //           className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
  //           onMouseEnter={
  //             !dashboard ? (e) => handleViewTooltip(e, data) : () => {}
  //           }
  //           // onMouseLeave={!dashboard ? () => handleHideTooltip() : () => {}}
  //         >
  //           {/* <Image
  //             src={option?.data?.image_url}
  //             width={16}
  //             height={16}
  //             alt={option?.data?.name}
  //           /> */}
  //         </div>
  //         <span className="pro-ms-1 pro-align-self-center name">
  //           {option?.data?.label}
  //         </span>
  //       </div>
  //     </components.MultiValueLabel>
  //   );
  // };

  // const customOption = (option) => {
  //   return (
  //     <components.Option {...option}>
  //       <div className="pro-d-flex pro-items-center">
  //         <div className={`pro-avatar department-${option?.data?.value}`}>
  //           {option?.data?.icon}
  //         </div>
  //         <span className="pro-ps-1">{option?.data?.label}</span>
  //       </div>
  //     </components.Option>
  //   );
  // };

  return (
    <div className={`pro-d-flex pro-gap-4 ${Style.select_container}`}>
      <Select
        // defaultMenuIsOpen
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        onMenuOpen={onMenuOpen}
        {...rest}
        onChange={onChange}
        options={options}
        classNamePrefix="pro-input"
         value={options?.filter((item) => item.value === data?.status)}
        className={`pro-input multi-select lg ${Style.pro_input}`}
        defaultValue={options?.filter((item) => item.value === data?.status)}
      ></Select>

      {noteDate && (
        <span className={`pro-font-xs pro-fw-bold ${Style.note_date}`}>
          {noteDate}
        </span>
      )}
      {noteIcon ? (
        <span className="pro-d-flex cursor-pointer">
          {notes === 0 ? (
            <span
              className={`material-symbols-outlined ${Style.chat_icon}`}
              onClick={() => handleAddNote(data)}
            >
              maps_ugc
            </span>
          ) : (
            <span className={Style.note} onClick={() => handleViewNote(data)}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.455078 21.4119L2.40508 14.7119C2.08841 14.0286 1.85091 13.3286 1.69258 12.6119C1.53424 11.8952 1.45508 11.1619 1.45508 10.4119C1.45508 9.02856 1.71758 7.72856 2.24258 6.5119C2.76758 5.29523 3.48008 4.2369 4.38008 3.3369C5.28008 2.4369 6.33841 1.7244 7.55508 1.1994C8.77174 0.674396 10.0717 0.411896 11.4551 0.411896C12.8384 0.411896 14.1384 0.674396 15.3551 1.1994C16.5717 1.7244 17.6301 2.4369 18.5301 3.3369C19.4301 4.2369 20.1426 5.29523 20.6676 6.5119C21.1926 7.72856 21.4551 9.02856 21.4551 10.4119C21.4551 11.7952 21.1926 13.0952 20.6676 14.3119C20.1426 15.5286 19.4301 16.5869 18.5301 17.4869C17.6301 18.3869 16.5717 19.0994 15.3551 19.6244C14.1384 20.1494 12.8384 20.4119 11.4551 20.4119C10.7051 20.4119 9.97174 20.3327 9.25508 20.1744C8.53841 20.0161 7.83841 19.7786 7.15508 19.4619L0.455078 21.4119ZM3.40508 18.4619L6.60508 17.5119C6.83841 17.4452 7.07591 17.4202 7.31758 17.4369C7.55925 17.4536 7.78841 17.5119 8.00508 17.6119C8.53841 17.8786 9.09675 18.0786 9.68008 18.2119C10.2634 18.3452 10.8551 18.4119 11.4551 18.4119C13.6884 18.4119 15.5801 17.6369 17.1301 16.0869C18.6801 14.5369 19.4551 12.6452 19.4551 10.4119C19.4551 8.17856 18.6801 6.2869 17.1301 4.7369C15.5801 3.1869 13.6884 2.4119 11.4551 2.4119C9.22174 2.4119 7.33008 3.1869 5.78008 4.7369C4.23008 6.2869 3.45508 8.17856 3.45508 10.4119C3.45508 11.0119 3.52174 11.6036 3.65508 12.1869C3.78841 12.7702 3.98841 13.3286 4.25508 13.8619C4.37174 14.0786 4.43424 14.3077 4.44258 14.5494C4.45091 14.7911 4.42174 15.0286 4.35508 15.2619L3.40508 18.4619Z"
                  fill="white"
                />
              </svg>
              <span
                className={`${Style.note_count} pro-fw-bolder pro-d-flex pro-justify-center pro-items-center pro-rounded-circle`}
              >
                {notes}
              </span>
            </span>
          )}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
