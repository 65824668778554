import Style from './avatarCard.module.scss';

const AvatarCard = ({
    extraClassName, 
    image,
    alt,
    name,
    icon,
    button,
    designation
}) => {
  return (
    <div className={`pro-d-flex pro-items-center ${Style.root} ${extraClassName ? extraClassName : ""}`}>
        {(image || icon) && <div className={`pro-avatar lg`}>
            {image ? <img src={image} alt={alt || ''}  /> : icon && <span>{icon}</span>}
        </div>}
        <div className={Style.content}>
            {name && <h4 className={Style.name}>{name}</h4>}
            {button && <button className={`pro-btn pro-btn-link`}>{button}</button>}
            {designation && <p className='pro-mb-0'>{designation}</p>}
        </div>
    </div>
  )
}

export default AvatarCard