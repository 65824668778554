import { AvatarGroup } from "@wac-ui-dashboard/wac_component_library";
import Style from "./kanBoard.module.scss";
import Assets from "../../../assets/Assets";

const KanBoard = ({
  extraClassName,
  title,
  data,
  avatar,
  subTitle,
  iconNote,
  showNotes,
  notes = 0,
  badgeText,
  lastElement,
  handleAddNote,
  handleViewNote,
  onClick,
  completed,
}) => {
  return (
    <div
      ref={lastElement || null}
      onClick={onClick}
      className={`${Style.root} ${
        extraClassName ? extraClassName : ""
      } pro-p-4 pro-ps-3`}
    >
      {title && (
        <h6 className={`pro-ttl pro-font-regular pro-mb-1`}>{title}</h6>
      )}
      {subTitle && <p className="pro-mb-0">{subTitle}</p>}
      {showNotes && (
        <div className={`pro-d-flex pro-justify-between ${Style.card_footer}`}>
          <div className={`pro-avatar ${Style.pro_avatar}`}>
            {/* <img src={avatar} alt={"cadd_image"} /> */}
            <span
              className={`material-symbols-outlined x3 ${Style.profile_icon}`}
            >
              account_circle
            </span>
          </div>

          <span className="pro-d-flex cursor-pointer pro-ms-auto">
            {notes === 0 ? (
              <span className={`${Style.icon} pro-font-xs`}>
                <span
                  className={`material-symbols-outlined`}
                  onClick={() => handleAddNote(data)}
                >
                  maps_ugc
                </span>
              </span>
            ) : (
              <span className={Style.note} onClick={() => handleViewNote(data)}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5 21.4119L2.45 14.7119C2.13333 14.0285 1.89583 13.3285 1.7375 12.6119C1.57917 11.8952 1.5 11.1619 1.5 10.4119C1.5 9.02853 1.7625 7.72853 2.2875 6.51187C2.8125 5.2952 3.525 4.23687 4.425 3.33687C5.325 2.43687 6.38333 1.72437 7.6 1.19937C8.81667 0.674365 10.1167 0.411865 11.5 0.411865C12.8833 0.411865 14.1833 0.674365 15.4 1.19937C16.6167 1.72437 17.675 2.43687 18.575 3.33687C19.475 4.23687 20.1875 5.2952 20.7125 6.51187C21.2375 7.72853 21.5 9.02853 21.5 10.4119C21.5 11.7952 21.2375 13.0952 20.7125 14.3119C20.1875 15.5285 19.475 16.5869 18.575 17.4869C17.675 18.3869 16.6167 19.0994 15.4 19.6244C14.1833 20.1494 12.8833 20.4119 11.5 20.4119C10.75 20.4119 10.0167 20.3327 9.3 20.1744C8.58333 20.016 7.88333 19.7785 7.2 19.4619L0.5 21.4119ZM3.45 18.4619L6.65 17.5119C6.88333 17.4452 7.12083 17.4202 7.3625 17.4369C7.60417 17.4535 7.83333 17.5119 8.05 17.6119C8.58333 17.8785 9.14167 18.0785 9.725 18.2119C10.3083 18.3452 10.9 18.4119 11.5 18.4119C13.7333 18.4119 15.625 17.6369 17.175 16.0869C18.725 14.5369 19.5 12.6452 19.5 10.4119C19.5 8.17853 18.725 6.28687 17.175 4.73687C15.625 3.18687 13.7333 2.41187 11.5 2.41187C9.26667 2.41187 7.375 3.18687 5.825 4.73687C4.275 6.28687 3.5 8.17853 3.5 10.4119C3.5 11.0119 3.56667 11.6035 3.7 12.1869C3.83333 12.7702 4.03333 13.3285 4.3 13.8619C4.41667 14.0785 4.47917 14.3077 4.4875 14.5494C4.49583 14.791 4.46667 15.0285 4.4 15.2619L3.45 18.4619Z"
                    fill="black"
                  />
                </svg>
                <span
                  className={`${Style.note_count} pro-fw-bolder pro-d-flex pro-justify-center pro-items-center pro-rounded-circle`}
                >
                  {notes}
                </span>
              </span>
            )}
          </span>

          {/* {(icon || completed || iconNote) && (
            <div className="pro-d-flex pro-ms-auto">
              {icon && (
                <span
                  className={`${Style.icon} ${
                    iconDisabled ? Style.disable : ""
                  } pro-font-xs`}
                >
                  {icon}
                </span>
              )}
              {iconNote && (
                <span className={Style.note}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.5 21.4119L2.45 14.7119C2.13333 14.0285 1.89583 13.3285 1.7375 12.6119C1.57917 11.8952 1.5 11.1619 1.5 10.4119C1.5 9.02853 1.7625 7.72853 2.2875 6.51187C2.8125 5.2952 3.525 4.23687 4.425 3.33687C5.325 2.43687 6.38333 1.72437 7.6 1.19937C8.81667 0.674365 10.1167 0.411865 11.5 0.411865C12.8833 0.411865 14.1833 0.674365 15.4 1.19937C16.6167 1.72437 17.675 2.43687 18.575 3.33687C19.475 4.23687 20.1875 5.2952 20.7125 6.51187C21.2375 7.72853 21.5 9.02853 21.5 10.4119C21.5 11.7952 21.2375 13.0952 20.7125 14.3119C20.1875 15.5285 19.475 16.5869 18.575 17.4869C17.675 18.3869 16.6167 19.0994 15.4 19.6244C14.1833 20.1494 12.8833 20.4119 11.5 20.4119C10.75 20.4119 10.0167 20.3327 9.3 20.1744C8.58333 20.016 7.88333 19.7785 7.2 19.4619L0.5 21.4119ZM3.45 18.4619L6.65 17.5119C6.88333 17.4452 7.12083 17.4202 7.3625 17.4369C7.60417 17.4535 7.83333 17.5119 8.05 17.6119C8.58333 17.8785 9.14167 18.0785 9.725 18.2119C10.3083 18.3452 10.9 18.4119 11.5 18.4119C13.7333 18.4119 15.625 17.6369 17.175 16.0869C18.725 14.5369 19.5 12.6452 19.5 10.4119C19.5 8.17853 18.725 6.28687 17.175 4.73687C15.625 3.18687 13.7333 2.41187 11.5 2.41187C9.26667 2.41187 7.375 3.18687 5.825 4.73687C4.275 6.28687 3.5 8.17853 3.5 10.4119C3.5 11.0119 3.56667 11.6035 3.7 12.1869C3.83333 12.7702 4.03333 13.3285 4.3 13.8619C4.41667 14.0785 4.47917 14.3077 4.4875 14.5494C4.49583 14.791 4.46667 15.0285 4.4 15.2619L3.45 18.4619Z"
                      fill="black"
                    />
                  </svg>

                  <span
                    className={`${Style.note_count} pro-fw-bolder pro-d-flex pro-justify-center pro-items-center pro-rounded-circle`}
                  >
                    {1}
                  </span>
                </span>
              )}

          
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default KanBoard;
