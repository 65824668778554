import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  notificationData: {},
  notificationStatus: "idle"
};

export const getNotificationData = createAsyncThunk(
  "global/getNotificationData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `v1/notification?length=15&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationData.pending, (state, action) => {
      state.notificationStatus = "pending";
    });
    builder.addCase(getNotificationData.fulfilled, (state, action) => {
     
      if (
        state.notificationData?.[action.meta?.arg?.notification_id] &&
        !action.meta?.arg?.clearState
      ) {
        if (action.payload?.data?.data?.rows?.data) {
          state.notificationData[action.meta?.arg?.notification_id] = {
            ...action.payload?.data?.data?.rows,
            data: [
              ...state.notificationData[action.meta?.arg?.notification_id].data,
              ...action.payload?.data?.data?.rows?.data,
            ],
          };
        } else {
          state.notificationData[action.meta?.arg?.notification_id] = {
            ...action.payload?.data?.data?.rows,
            data: [
              ...state.notificationData[action.meta?.arg?.notification_id].data,
            ],
          };
        }
      } else {
        state.notificationData[action.meta?.arg?.notification_id] = {
          ...action.payload?.data?.data?.rows,
          page: 1,
        };
      }
      state.notificationStatus = "fulfilled";
    });
    builder.addCase(getNotificationData.rejected, (state, action) => {
      state.notificationStatus = "failed";
    });
  },
});

export const { updateConfig } = notificationSlice.actions;

export default notificationSlice.reducer;
