import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import {
  students,
  useGetCourseDataQuery,
  useGetFacultyDataQuery,
  useGetStudentDetailsDataQuery,
} from "../../../store/queries/students";
import { toast } from "react-toastify";
import {
  courseAssign,
  updateConfig,
} from "../../../store/slices/Students/studentsProfileSlice";
import { numRegExp } from "../../../utils/functions/table";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  updateConfig as updateConfigStudent,
  getCourseModuleList,
} from "../../../store/slices/Students/studentsSlice";

const useCourseAssign = ({ closeModal, profileState }) => {
  const dispatch = useDispatch();
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetCourseDataQuery({});
  const { data: facultyData = {}, isSuccess: isDataSuccess } =
    useGetFacultyDataQuery({});

  const { courseModuleList, selectedCourseId } = useSelector(
    (state) => state.students
  );

  const facultyList = facultyData?.data?.map(({ user: { full_name }, id }) => ({
    label: full_name,
    value: id,
  }));
  const moduleList = courseModuleList?.map(({ title, id }) => ({
    label: title,
    value: id,
  }));

  useEffect(() => {
    if (courseModuleList?.length !== 0) {
      courseModuleList?.map((item, itemIndex) => {
        formik?.setFieldValue(
          `courses.${itemIndex}.course_module_id`,
          item?.id
        );
        formik?.setFieldValue(`courses.${itemIndex}.faculty_id`, "");
        formik?.setFieldValue(`courses.${itemIndex}.module_end_date`, "");
        formik?.setFieldValue(`courses.${itemIndex}.module_start_date`, "");
      });
    }
    //eslint-disable-next-line
  }, [courseModuleList?.length]);

  useEffect(() => {
    if (selectedCourseId) {
      dispatch(getCourseModuleList(selectedCourseId));
    }
    //eslint-disable-next-line
  }, [selectedCourseId]);
  const validation = Yup.object({
    course_id: Yup.string().required("*Course Required"),
    // courses: Yup.array().of(
    //   Yup.object().shape({
    //     course_module_id: Yup.string(),
    //     faculty_id: Yup.string().required("Select Faculty"),
    //     module_start_date: Yup.string().required("*Required"),
    //     module_end_date: Yup.string().required("*Required"),
    //   })
    // ),
    weekly_hours: Yup.string()
      .matches(numRegExp, "Enter hours in number")
      .required("*Weekly hours required"),
    course_start_date: Yup.string().required("*Course start date required"),
  });

  const formik = useFormik({
    initialValues: {
      course_id: "",
      // courses: [
      //   {
      //     course_module_id: "",
      //     faculty_id: "",
      //     module_start_date: "",
      //     module_end_date: "",
      //   },
      // ],
      weekly_hours: "",
      course_start_date: "",
    },

    validationSchema: validation,

    // validationSchema: createEnquiryVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        course_id: values.course_id,
        weekly_hours: values?.weekly_hours,
        course_start_date: values.course_start_date,
      };

      let newObj = Object.entries(obj);

      newObj = newObj
        .filter((item) => item[1] !== undefined && item[1] !== null)
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      let formData = new FormData();

      Object.keys(newObj).forEach((key) => {
        return formData.append(key, newObj[key]);
      });

      values?.courses?.map((value, index) => {
        return (
          formData.append(
            `modules[${index}][course_module_id]`,
            value?.course_module_id
          ),
          formData.append(`modules[${index}][faculty_id]`, value?.faculty_id),
          formData.append(
            `modules[${index}][module_start_date]`,
            value?.module_start_date
          ),
          formData.append(
            `modules[${index}][module_end_date]`,
            value?.module_end_date
          )
        );
      });

      dispatch(
        courseAssign({ student_id: profileState.data.rows.id, data: formData })
      ).then((response) => {
        if (response?.payload?.status_code === 200) {
          resetForm();
          dispatch(students.util.invalidateTags(["profileCourse"]));
          dispatch(students.util.invalidateTags(["studentDetailsReport"]));
          dispatch(
            updateConfig((state) => {
              state.queryData = {};
            })
          );
          closeModal?.();
          dispatch(
            updateConfig((state) => {
              state.showCourseCreateModal = false;
            })
          );

          toast.success(response?.payload?.message);
        } else if (response?.payload?.status_code === 400) {
          formik.setErrors(response?.payload?.message);
        } else toast.error(response?.payload?.message);
      });
    },
  });

  const handleCourseChange = (value) => {
    dispatch(
      updateConfigStudent((state) => {
        state.selectedCourseId = value?.id;
      })
    );
    formik.setFieldValue("courses", []);
    formik.setFieldValue("course_id", value.id);
  };

  return {
    formik,
    optionsData,
    facultyData,
    moduleList,
    facultyList,
    handleCourseChange,
  };
};

export default useCourseAssign;
