import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getNotificationData,
  updateConfig,
} from "../../../store/slices/Notifications/notificationSlice";
import { useSelector } from "react-redux";
import useObserver from "../../../utils/hooks/useObserver";
import { useNavigate } from "react-router-dom";
import { useUpdateNotificationDataMutation } from "../../../store/queries/notifications";
import { useSearchParams } from "react-router-dom/dist";

const useNotificationListing = () => {
  const navigate = useNavigate();
  const [showScroll, setShowScroll] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const state = useSelector((state) => state.notification);
  const notificationState = state?.notificationData?.["notificationData"];
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  // const [updateNotification] = useUpdateNotificationDataMutation();

  const NotificationRedirectType = {
    profile_view: 1,
    view_master_data_updation: 2,
    messages_list: 3,
    transactions_list: 4,
    deleted_list: 5,
    package_expired_members_list: 6,
    reported_list: 7,
    users_blocked_list: 8,
    transfers_list: 9,
    members_list: 10,
    verification_list: 11,
    followup_list: 12,
    view_verification_photo: 13,
    view_verification_document: 14,
  };

  useEffect(() => {
    if (Object.keys(state?.notificationData)?.length === 0) {
      dispatch(
        getNotificationData({
          page: page,
          notification_id: "notificationData",
        })
      );
    }

    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        // state.currentPage = state.currentPage + 1;
        state.notificationData["notificationData"].page += 1;
      })
    );
    setPage((prev) => prev + 1);
    dispatch(
      getNotificationData({
        page: page + 1,
        notification_id: "notificationData",
      })
    );
  };

  const { lastElement } = useObserver({
    loading: state?.notificationStatus === "pending",
    hasMore:
      notificationState?.per_page * notificationState?.current_page <
      notificationState?.total,
    callback: handleLoadMore,
  });

  const findRedirectTypeKey = (redirectType) => {
    for (const key in NotificationRedirectType) {
      if (NotificationRedirectType[key] === redirectType) {
        return key;
      }
    }
    return null;
  };

  const handleRedirect = (type, id, userId) => {
    // const key = findRedirectTypeKey(type);

    // let formData = new FormData();
    // formData.append("_method", "PUT");
    // formData.append("notification_id", id);

    // updateNotification(formData).then((response) => {
    //   if (response?.data?.status_code === 200) {
    //     dispatch(
    //       getNotificationData({
    //         page: page,
    //         notification_id: "notificationData",
    //       })
    //     );
    //   }
    // });

    // const ids = params.get("id")
    //   ? [...params.get("id").split(","), activeProfile]
    //   : [activeProfile];

    // const newIds = ids.filter((id) => id !== userId).join(",");

    // switch (key) {
    //   case "profile_view":
    //     window.open(
    //       `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    //     );
    //     break;
    //   case "view_master_data_updation":
    //     navigate("/support/data-updation");
    //     break;
    //   case "messages_list":
    //     navigate("/support/complaints");
    //     break;
    //   case "transactions_list":
    //     navigate("/payment/transactions");
    //     break;
    //   case "deleted_list":
    //     navigate("/users/deleted-accounts");
    //     break;
    //   case "package_expired_members_list":
    //     navigate("/users/");
    //     break;
    //   case "reported_list":
    //     navigate("/users/reported");
    //     break;
    //   case "users_blocked_list":
    //     navigate("/users/blocked");
    //     break;
    //   case "transfers_list":
    //     navigate("/support/transfers");
    //     break;
    //   case "members_list":
    //     navigate("/users/");
    //     break;
    //   case "verification_list":
    //     window.open(
    //       `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    //     );
    //     break;
    //   case "followup_list":
    //     navigate("/users/followup");
    //     break;
    //   case "view_verification_photo":
    //     window.open(
    //       `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    //     );
    //     break;
    //   case "view_verification_document":
    //     window.open(
    //       `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    //     );
    //     break;

    //   default:
    //     navigate("/");
    //     break;
    // }
  };

  return {
    showScroll,
    state,
    handleRedirect,
    notificationState,
    lastElement,
    handleScrollToTop,
  };
};

export default useNotificationListing;
