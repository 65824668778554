import { Auth } from "@wac-ui-dashboard/wac_component_library";
import { useOutletContext } from "react-router-dom";
import useAuth from "./useAuth";

const Login = () => {
  const { emailRef } = useOutletContext();
  const {
    formik,
    loginStatus,
    showPassword,
    forgotPasswordStatus,
    handleForgotPassword,
    handleShowPassword,
  } = useAuth(emailRef);
  return (
    <Auth
      title={"Login"}
      buttonText={"Login"}
      handleClick={formik.handleSubmit}
      actionText="Forgot Password?"
      handleActionClick={handleForgotPassword}
      loading={loginStatus === "pending"}
      actionLoading={forgotPasswordStatus === "pending"}
    >
      <div className="input-wrap pro-mb-5">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Email
        </label>
        <input
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="text"
          className={`pro-input lg ${
            formik.errors.email &&
            (formik.touched.email || formik.values.email) &&
            "error"
          } ${
            loginStatus === "pending" || forgotPasswordStatus === "pending"
              ? "disabled"
              : ""
          } `}
        />
        {formik.errors.email &&
          (formik.touched.email || formik.values.email) && (
            <span className="error-text">{formik?.errors?.email}</span>
          )}
      </div>

      <div className="input-wrap pro-mb-5 p-re">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Password
        </label>
        <input
          name="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="password"
          className={`pro-input lg ${
            formik.errors.password && formik.touched.password && "error"
          } ${
            loginStatus === "pending" || forgotPasswordStatus === "pending"
              ? "disabled"
              : ""
          } hide-icon-input`}
        />

        {showPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowPassword(e)}
            type="button"
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowPassword(e)}
            type="button"
          >
            <span className="material-symbols-outlined"> visibility </span>
          </button>
        )}
        {formik.errors.password && formik.touched.password && (
          <span className="error-text">{formik?.errors?.password}</span>
        )}
      </div>
    </Auth>
  );
};

export default Login;
