import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stream_id: "",
  course_status: "",
  course_id: "",
  branch_id: "",
  join_start_date: "",
};

const studentFilterSlice = createSlice({
  name: "studentsFilter",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: () => initialState,
  },
});

export const { updateConfig, resetConfig } = studentFilterSlice.actions;
export default studentFilterSlice.reducer;
