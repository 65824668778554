import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  emailRegExp,
  numRegExp,
  phoneRegExp,
} from "../../../../utils/functions/table";

import { useSelector } from "react-redux";
import {
  useCreateBranchesMutation,
  useUpdateBranchesMutation,
} from "../../../../store/queries/branches";
import {
  updateConfig,
  createBranches,
  updateBranches,
} from "../../../../store/slices/Branches/branchesSlice";
import countryData from "../../../../utils/components/countryCode";

const useBranchForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const { selectedItemsDetails } = useSelector((state) => state.branches);

  const [imagePreview, setImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.enquiry?.url : ""
  );

  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.user?.image_url : ""
  );

  // const [createBranch] = useCreateBranchesMutation();
  // const [updateBranch] = useUpdateBranchesMutation();

  const stateRef = useRef(" ");

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setImageCoverPreview(selectedItemsDetails?.user?.image_url ?? "");
    }
  }, [selectedItemsDetails]);

  const passwordValidation = Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .required("*Required");
  const passwordValidationUpdate = Yup.string().min(
    8,
    "Password is too short - should be 8 chars minimum."
  );

  const confirmValidation = Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .required("*Required");

  // let YupValidationObject = {
  //   first_name: Yup.string()
  //     .min(2, "The Name must be at least 2 characters")
  //     .max(20, "max 20 characters allowed")
  //     .matches(/^[a-zA-Z]+$/, "Invalid name")
  //     .required("*Required"),
  //   mobile_number: Yup.string()
  //     .matches(phoneRegExp, "Please enter a valid phone number")
  //     .min(7, "The phone number must be between 7 and 14 digits")
  //     .max(14, "The phone number must be between 7 and 14 digits")
  //     .required("*Required"),
  //   email: Yup.string()
  //     .matches(emailRegExp, "Please enter a valid email")
  //     .required("*Required"),
  //     city: Yup.string().required("*Required"),
  //   country_id: Yup.object().required("*Required"),
  //   address_one: Yup.string().required("*Required"),
  //   password: passwordValidation,
  //   confirm_password: confirmValidation,
  // };

  // let createEnquiryVal = Yup.object(YupValidationObject);

  const validation = Yup.object({
    first_name: Yup.string()
      .min(2, "The first name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("*Required"),
    last_name: Yup.string().max(50, "max 50 characters allowed"),
    mobile_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*Required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    city: Yup.string().required("*Required"),
     state_id: Yup.string().required("*Required"),
    country_id: Yup.string().required("*Required"),
    address_one: Yup.string().required("*Required"),
    password: selectedItemsDetails === "" ? passwordValidation : "",
    confirm_password: selectedItemsDetails === "" ? confirmValidation : "",
    zipcode: Yup.string()
    .matches(/^[0-9]+$/, 'Zipcode must contain only numeric digits')
    .max(10, "max 10 characters allowed"),
  });


  const formik = useFormik({
    initialValues: {
      first_name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.user?.first_name
          : "",
      last_name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.user?.last_name !== null
            ? selectedItemsDetails?.user?.last_name
            : ""
          : "",
      dial_code:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.user?.dial_code
          : "+91",
      mobile_number:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.user?.mobile_number
          : "",
      email:
        selectedItemsDetails !== "" ? selectedItemsDetails?.user?.email : "",
      password:
        selectedItemsDetails !== "" ? selectedItemsDetails?.user?.password : "",
      city: selectedItemsDetails !== "" ? selectedItemsDetails?.city : "",
      state_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.state_id : "",
      country_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.country_id : "",
      zipcode: selectedItemsDetails !== "" ? selectedItemsDetails?.zipcode : "",
      address_one:
        selectedItemsDetails !== "" ? selectedItemsDetails?.address_one : "",
      address_two:
        selectedItemsDetails !== "" ? selectedItemsDetails?.address_two : "",
      confirm_password:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.user?.confirm_password
          : "",
          image:selectedItemsDetails !== ""
          ? selectedItemsDetails?.user?.original_image_name
          : "",
    },

    validationSchema: validation,
    validate: (values) => {
      let errors = {};
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      return errors;
    },
    // validationSchema: createEnquiryVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        first_name: values.first_name,
        last_name: values.last_name ? values.last_name : "",
        mobile_number: values.mobile_number,
        dial_code: values.dial_code,
        email: values.email,
        password: values.password,
        city: values.city,
        zipcode: values.zipcode,
        state_id: values.state_id ? values.state_id : "",
        country_id: values.country_id,
        address_one: values.address_one,
        address_two: values.address_two,
        confirm_password: values.confirm_password,
      };

      if (values.image?.name) {
        obj.image = values.image;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        dispatch(
          updateBranches({ id: selectedItemsDetails.id, data: formData })
        ).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createBranches(formData)).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const cuntryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  const handleCountryId = (value) => {
    formik.setFieldValue("country_id", value.id);
  };

  const handlePhoneNumberChange = (value) => {
    if (value !== formik.values.mobile_number) {
      dispatch(
        updateConfig((state) => {
          state.phoneVerified = {
            verified: false,
            status: false,
          };
        })
      );
    }
    formik.setFieldValue("mobile_number", value);
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleCoverImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageCoverPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    // isLoading,
    imageCoverPreview,
    imagePreview,
    selectedItemsDetails,
    profilefileInputRef,
    handleCoverImage,
    handleCloseModal,
    cuntryCodeOptions,
    handlePhoneNumberChange,
    handleShowPassword,
    handleShowConfirmPassword,
    showPassword,
    showConfirmPassword,
    stateRef,
    handleCountryId,
  };
};

export default useBranchForm;
