import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  transferNote: "",
  IDs: {},
  selectedItemsDetails: "",
  is_active: 1,
  phoneVerified: {
    verified: false,
    status: false,
  },
  is_edit: false,
};

export const getBranchDataByID = createAsyncThunk(
  "v1/branch/show",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/branch/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBranches = createAsyncThunk(
  "/v1/branch/store",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/branch/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBranches = createAsyncThunk(
  "/v1/branch/update",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/branch/update/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "/v1/branch/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/branch/delete/${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    
   builder.addCase(getBranchDataByID.pending, (state) => {
      state.isLoading = true;
      state.selectedItemsDetails = {}
      state.error = null;
    })
    .addCase(getBranchDataByID.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    })
    .addCase(getBranchDataByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    
  },
  
});

export const { updateConfig } = branchesSlice.actions;

export default branchesSlice.reducer;
