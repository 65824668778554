import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";
import { toast } from "react-toastify";


const useOrderColoum = ({ tableFields, moduleId, updateData, fixedFields }) => {


  let fieldsToChange = Object.entries(tableFields);
  let fixedFieldsToAdd = Object.entries(fixedFields);

  fieldsToChange = fieldsToChange.reduce(
    (a, v) => ({ ...a, [v[0]]: { ...v[1], key_id: v[0] } }),
    {}
  );

  fixedFieldsToAdd = fixedFieldsToAdd.reduce(
    (a, v) => ({ ...a, [v[0]]: { ...v[1], key_id: v[0] } }),
    {}
  );

  const [characters, setCharacters] = useState(Object.values(fieldsToChange));

  const dispatch = useDispatch();
  
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCharacters(items);
  }

  const handleCancel = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleSubmit = () => {
    let tempData = []; // eslint-disable-next-line

    if (Object.values(fixedFieldsToAdd).length > 0) {
      Object.values(fixedFieldsToAdd).map((item, index) => {
        let data = {
          column_name: item.key_id,
          label: item.label,
          position: index,
          sortable: item.sortable,
          hidden: item.hidden,
        };

        return tempData.push(data);
      });
    }

    Object.values(characters).map((item, index) => {
      let data = {
        column_name: item.key_id,
        label: item.label,
        position: index + 2,
        sortable: item.sortable,
        hidden: characters?.[index]?.hidden,
      };
      return tempData.push(data);
    });

    const obj = {
      module_id: parseInt(moduleId),
      fields: tempData,
    };

    updateData(obj).then((response) => {
      if (response?.data?.status_code === 200) {
        // refetchData?.();
        dispatch(
          updateConfig((state) => {
            state.showEditModal = false;
          })
        );
        toast.success("Fields updated Successfully!");
      } else if (response?.status_code === 400) {
        toast.error(response?.message);
      }
    });
  };

  const handleCheckbox = (Label) => {
    function getKeyByValue(object, Label) {
      return Object.keys(object).find((key) => object[key].label === Label);
    }

    let tempData = characters;
    let key = getKeyByValue(tempData, Label);
    tempData[key] = { ...tempData[key], hidden: !tempData[key].hidden };

    setCharacters(Object.values(tempData));
  };

  return {
    characters,
    // message,
    handleCancel,
    handleSubmit,
    handleOnDragEnd,
    handleCheckbox,
  };
};

export default useOrderColoum;
