import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { storeTimeSchedule } from "../../../TimeTables/TimeTable/api";
import {
  useGetDaysListDataQuery,
  useGetFacultyListDataQuery,
  useGetTimeSlotListDataQuery,
  // useGetTimeTableListDataQuery,
} from "../../../../store/queries/timeTable";
import { updateConfig } from "../../../../store/slices/Students/studentsProfileSlice";
import { updateConfig as studentUpdateConfig} from "../../../../store/slices/Students/studentsSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useGetCourseDataQuery } from "../../../../store/queries/students";
import {
  getCourseModuleList,
  getCourseModuleSlots,
} from "../../../../store/slices/Students/studentsSlice";
import { useSelector } from "react-redux";
import { timeTable } from "../../../../store/queries/timeTable";
import { format, parse } from "date-fns";
import { getIsButtonEnable } from "../../StudentsListing/api";
import { getFormatedDate } from "../../../../utils/functions/table";

const useTimeScheduleForm = ({
  studentData,
  studentId,
  courseRefetch,
  courseId,
}) => {
  const { data: facultyData } = useGetFacultyListDataQuery();
  const { data: daysListData } = useGetDaysListDataQuery();
  const { data: timeSlotListData } = useGetTimeSlotListDataQuery();
  const { data: courseListData } = useGetCourseDataQuery();

  const { courseModuleList, slotData } = useSelector((state) => state.students);
  const dispatch = useDispatch();
  let selectedStudent = studentData?.rows?.courses?.filter(
    (item) => item?.id === courseId
  );
  let selectedModule = selectedStudent?.[0]?.modules?.filter(
    (item) => item?.id === studentId
  );

  let { student_id, student_name } = { ...studentData?.rows };
  let studentList = {
    label: student_name,
    value: student_id,
  };

  const [selectedDays, setSelectedDays] = useState([]);

  const handleDayChange = (day) => {
    const isSelected = selectedDays.includes(day);

    const updateTimetableEntries = (newEntries) => {
      // Remove days with empty arrays
      Object.keys(newEntries).forEach((key) => {
        if (newEntries[key].length == 0) {
          delete newEntries[key];
        }
      });

      return newEntries;
    };

    if (isSelected) {
      const updatedDays = selectedDays?.filter((d) => d !== day);
      setSelectedDays(updatedDays);
      formik.setFieldValue(`timetable_entries.days`, {
        ...formik?.values?.timetable_entries?.days,
        [day]: undefined,
      });

      const updatedTimetableEntries = {
        ...formik?.values?.timetable_entries?.days,
        [day]: [],
      };

      formik.setFieldValue(
        "timetable_entries.days", updatedTimetableEntries
      );
    } else {
      const updatedDays = [...selectedDays, day];
      setSelectedDays(updatedDays);

      const updatedTimetableEntries = {
        ...formik?.values?.timetable_entries?.days,
        [day]: [],
      };

      formik.setFieldValue(
        "timetable_entries.days", updatedTimetableEntries
      );
    }
  };

  const [fieldIndex, setFieldIndex] = useState(0);
  useEffect(() => {
    setInitialData({
      faculty_id: selectedModule?.[0]?.faculty_id,
      course_id: selectedModule?.[0]?.course_id,
      course_module_id: selectedModule?.[0]?.course_module_id,
      student_id: student_id,
      module_start_date: getFormatedDate(
        selectedModule?.[0]?.module_start_date ?? ""
      ),
      weekly_hours: selectedModule?.[0]?.weekly_hours ?? "",
    });

    formik.setValues({
      ...formik.values,
      faculty_id: selectedModule?.[0]?.faculty_id,
      course_id: selectedModule?.[0]?.course_id,
      course_module_id: selectedModule?.[0]?.course_module_id,
      student_id: student_id,
      module_start_date: getFormatedDate(
        selectedModule?.[0]?.module_start_date ?? ""
      ),
      weekly_hours: selectedModule?.[0]?.weekly_hours ?? "",
    });
    //eslint-disable-next-line
  }, [studentId]);

  useEffect(() => {
    if (selectedModule?.[0]?.course_id) {
      dispatch(getCourseModuleList(selectedModule?.[0]?.course_id));
    }
    //eslint-disable-next-line
  }, [selectedModule?.[0]?.course_id]);

  useEffect(() => {
    if (selectedModule?.[0]?.faculty_id?.length !== 0) {
      let params = {
        faculty_id: selectedModule?.[0]?.faculty_id,
        student_id: student_id,
        course_id: selectedModule?.[0]?.course_id,
        course_module_id: selectedModule?.[0]?.course_module_id,
      };
      dispatch(getCourseModuleSlots(params));
    }
    //eslint-disable-next-line
  }, [selectedModule?.[0]?.faculty_id]);

  useEffect(() => {
    // return { timetable_entries: timetableEntries };
    

    if (slotData?.length !== 0) {
      const timetableEntries = { days: {} };

      slotData?.forEach((item) => {
        timetableEntries.days[item.day_id] = item?.time_slot_ids;
      });

      formik.setValues({
        ...formik.values,
        timetable_entries: timetableEntries ?? {},
      });
      // Update selectedDays state based on slotData
      const daysFromSlotData = slotData?.map((item) => item.day_id);
      setSelectedDays(daysFromSlotData);
    }

    //eslint-disable-next-line
  }, [slotData]);

  const [initialData, setInitialData] = useState({
    faculty_id: "",
    course_id: "",
    course_module_id: "",
    student_id: "",
    module_start_date: "",
    weekly_hours: "",
    timetable_entries: {
      days: {},
    },
  });

  const facultyList = facultyData?.data?.map(({ user: { full_name }, id }) => ({
    label: full_name,
    value: id,
  }));

  const daysList = daysListData?.data?.map(({ day, id }) => ({
    label: day,
    value: id,
  }));
  const courseList = courseListData?.data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const moduleList = courseModuleList?.map(({ title, id }) => ({
    label: title,
    value: id,
  }));

  const validation = Yup.object().shape({
    faculty_id: Yup.string().required("Faculty is required"),
    course_id: Yup.string().required("Course is required"),
    course_module_id: Yup.string().required("Module is required"),
    student_id: Yup.string().required("Student is required"),
    module_start_date: Yup.date().required("Module start date is required"),
    weekly_hours: Yup.string().required("Weekly hours are required"),
    // timetable_entries: Yup.mixed().required("At least one Time Slot entry is required")
  });

  const handleButtonDisplay = (params) => {
    getIsButtonEnable(params).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.isButtonFalse = response?.data?.data?.is_editable;
          })
        );
      } else {
        dispatch(
          updateConfig((state) => {
            state.isButtonFalse = response?.data?.data?.is_editable;
          })
        );
      }
    });
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validation,
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};

      // Custom validation for days and time slots
      if (selectedDays.length) {
        
        let daysErrors = {};
        selectedDays.forEach(dayId => {
          if (!values?.timetable_entries?.days[dayId] || values?.timetable_entries?.days[dayId].length === 0) {
            daysErrors[dayId] = `At least one time slot is required for ${daysList.find(day => day.value === dayId)?.label || 'this day'}`;
          }
        });
        if (Object.keys(daysErrors).length > 0) {
          errors.timetable_entries = { days: daysErrors };
        }
      }
  
      return errors;
  
    },
  
    onSubmit: (values) => {
       // Remove keys with empty arrays from timetable_entries.days
       let filteredDays;
       let payload;
       if(values?.timetable_entries?.days) {
        filteredDays = Object.keys(values?.timetable_entries?.days).reduce(
          (acc, dayId) => {
            if (values.timetable_entries?.days[dayId].length > 0) {
              acc[dayId] = values.timetable_entries?.days[dayId];
            }
            return acc;
          },
          {}
        );

        payload = {
          ...values,
          timetable_entries: {
            ...values?.timetable_entries,
            days: filteredDays,
          },
        };
       }
       else {
        payload = {
          ...values,
        };
       }
       
      let params = {
        course_id: values?.course_id,
        student_id: +values?.student_id,
      };
      storeTimeSchedule(payload).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          dispatch(
            timeTable.util.invalidateTags(["TimeTable", "TimeSchedule"])
          );
          handleButtonDisplay(params);
          courseRefetch();
          toast.success(`Time Scheduled`);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
              state.slotData = [];
            })
          );
          dispatch(
            studentUpdateConfig((state) => {
              state.clearSelection = true;
              state.slotData = []
            })
          )
          setSelectedDays([]);
        } else if (response?.data?.status_code === 422) {
          toast.error(response?.data?.message);
          handleCloseModal();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
              state.slotData = [];
            })
          );
          dispatch(
            studentUpdateConfig((state) => {
              state.clearSelection = true;
              state.slotData = []
            })
          )
          setSelectedDays([]);
        } else {
          toast.error(response?.message);
          handleCloseModal();
          setSelectedDays([]);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
              state.slotData = [];
            })
          );
          dispatch(
            studentUpdateConfig((state) => {
              state.clearSelection = true;
              state.slotData = []
            })
          )
        }
      });
    },
  });

  const handleFacultyChange = (value) => {
    // setFacultyId(value?.value);
    formik.setFieldValue("faculty_id", value?.value ?? "");
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAssignModal = false;
      })
    );
  };
  
  let facultyObj = facultyList?.filter(
    (item) => item?.value === formik?.values?.faculty_id
  );

  let courseObj = courseList?.filter(
    (item) => item?.value === formik?.values?.course_id
  );
  let moduleObj = moduleList?.filter(
    (item) => item?.value === formik?.values?.course_module_id
  );
  const reverseDateString = (dateString) => {
    const parts = dateString?.split("-");
    const reversedDateString = parts?.[0] + "-" + parts?.[1] + "-" + parts?.[2];
    return reversedDateString;
  };
  let dateStartDateStr = reverseDateString(formik.values.module_start_date);
  const handleStartDateChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("module_start_date", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  return {
    formik,
    daysList,
    courseList,
    facultyObj,
    moduleObj,
    courseObj,
    moduleList,
    studentList,
    facultyList,
    dateStartDateStr,
    timeSlotList: timeSlotListData?.data,
    setFieldIndex,
    // handleAddField,
    handleCloseModal,
    handleFacultyChange,
    handleStartDateChange,
    handleDayChange,
    selectedDays,
  };
};

export default useTimeScheduleForm;
