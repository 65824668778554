import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React, { useState } from "react";
import Select from "react-select";
import useTimeScheduleForm from "./useTimeScheduleForm";
import TimeSlotEntries from "./TimeSlotEntries";
import Style from "./TimeSlotEntries/timeSlotEntries.module.scss";
import { toast } from "react-toastify";

const TimeScheduleForm = ({
  studentData,
  studentId,
  courseRefetch,
  courseId,
}) => {
  const {
    formik,
    facultyObj,
    facultyList,
    courseList,
    studentList,
    daysList,
    moduleList,
    moduleObj,
    courseObj,
    timeSlotList,
    dateStartDateStr,
    handleAddField,
    handleFacultyChange,
    handleCloseModal,
    handleStartDateChange,
    handleDayChange,
    selectedDays,
  } = useTimeScheduleForm({ studentData, studentId, courseRefetch, courseId });

  // const handleFormSubmit = async () => {
  //   console.log("here", formik.errors)
  //   const errors = await formik.validateForm();
  //   console.log("here11", formik.errors)
  //   if (Object.keys(errors).length > 0) {
  //     toast.error("Please fix the validation errors before submitting.");
  //   } else {
  //     formik.submitForm();
  //   }
  // };


  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Faculty
          </label>
          <Select
            id="faculty_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.faculty_id && formik.errors.faculty_id && " error"
            }`}
            classNamePrefix="pro-input"
            options={facultyList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={facultyObj}
            onBlur={formik.handleBlur("faculty_id")}
            onChange={(value) => handleFacultyChange(value)}
          />
          {formik.touched.faculty_id && formik.errors.faculty_id && (
            <span className="error-text">{formik.errors.faculty_id}</span>
          )}{" "}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Course
          </label>
          <Select
            id="course_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.course_id && formik.errors.course_id && " error"
            }`}
            classNamePrefix="pro-input"
            options={courseList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={courseObj}
            onBlur={formik.handleBlur("course_id")}
            onChange={(value) => {
              formik.setFieldValue("course_id", value?.value ?? "");
            }}
            isDisabled
          />
          {formik.touched.course_id && formik.errors.course_id && (
            <span className="error-text">{formik.errors.course_id}</span>
          )}{" "}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Software Name
          </label>
          <Select
            id="course_module_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.course_module_id &&
              formik.errors.course_module_id &&
              " error"
            }`}
            classNamePrefix="pro-input"
            options={moduleList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={moduleObj}
            onBlur={formik.handleBlur("course_module_id")}
            onChange={(value) => {
              formik.setFieldValue("course_module_id", value?.value ?? "");
            }}
            isDisabled
          />
          {formik.touched.course_module_id &&
            formik.errors.course_module_id && (
              <span className="error-text">
                {formik.errors.course_module_id}
              </span>
            )}{" "}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Student
          </label>
          <Select
            id="student_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.student_id && formik.errors.student_id && " error"
            }`}
            classNamePrefix="pro-input"
            options={studentList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={studentList}
            onBlur={formik.handleBlur("student_id")}
            onChange={(value) => {
              formik.setFieldValue("student_id", value?.value ?? "");
            }}
            isDisabled
          />
          {formik.touched.student_id && formik.errors.student_id && (
            <span className="error-text">{formik.errors.student_id}</span>
          )}{" "}
        </div>
        <div className="input-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Module Start Date*
          </label>
          <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
            <Input
              value={
                formik.values.module_start_date === ""
                  ? "yyyy-MM-dd"
                  : dateStartDateStr
              }
              type={"date"}
              className={`pro-input lg ${
                formik.touched.module_start_date &&
                formik.errors.module_start_date &&
                "error"
              }`}
              onChange={handleStartDateChange}
              onBlur={formik.handleBlur("module_start_date")}
              error={
                formik.touched.module_start_date &&
                formik.errors.module_start_date
              }
              errorMessage={formik.errors.module_start_date}
              // disabled={true}
            />
          </div>
        </div>
        <Input
          label={"Weekly Hours*"}
          id={`weekly_hours`}
          name={`weekly_hours`}
          type="text"
          {...formik.getFieldProps(`weekly_hours`)}
          className={`pro-input lg ${
            formik.touched.weekly_hours &&
            formik.errors.weekly_hours &&
            " error"
          }`}
          error={formik.touched.weekly_hours && formik.errors.weekly_hours}
          errorMessage={formik.errors.weekly_hours}
          // disabled={true}
        />
        <div className={Style.address}>
          {/* {formik?.values?.timetable_entries?.map((fields, index) => {
            return (
              <TimeSlotEntries
                key={index}
                formik={formik}
                fields={fields}
                fieldIndex={index}
                daysList={daysList}
                timeSlotList={timeSlotList}
                handleAddField={handleAddField}
              />
            );
          })}

          <Button
            className={`pro-btn-primary lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
          >
            {` Add Another`}
          </Button> */}

          <div className={Style.formFields}>
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Days
              </label>
              <div className={`pro-w-100`}>
                {daysList?.map((day, index) => {
                  const isDaySelected = selectedDays.includes(day.value);
                  return (
                    <div key={day.label} className={Style.checkboxWrap}>
                      <div className="row gx-4">
                        <div className="col-4">
                          <div className="pro-check-box">
                            <input
                              type="checkbox"
                              value={day.value}
                              checked={isDaySelected}
                              onChange={() => handleDayChange(day.value)}
                              className="pro-check"
                              id={`timeslot-${index}`}
                            />
                            <label
                              htmlFor={`timeslot-${index}`}
                              className="pro-check-label pro-ps-2"
                            >
                              {day.label}
                            </label>
                          </div>
                        </div>
                        {isDaySelected && (
                          <div className="col-8">
                            <div className="input-wrap">
                              <label className="pro-font-sm pro-mb-1 pro-fw-medium">
                                Time Slots for {day.label}
                              </label>
                              <Select
                                id={`time_slot_ids_${day.value}`}
                                name={`timetable_entries.days[${day.value}]`}
                                isClearable={false}
                                isMulti
                                placeholder={"Select"}
                                className={`pro-input lg ${
                                  formik.touched?.timetable_entries?.days?.[
                                    day.value
                                  ] &&
                                  formik.errors?.timetable_entries?.days?.[
                                    day.value
                                  ] &&
                                  "error"
                                }`}
                                classNamePrefix="pro-input"
                                options={timeSlotList}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.slot}
                                value={timeSlotList?.filter((m) =>
                                  formik.values?.timetable_entries?.days?.[
                                    day.value
                                  ]?.includes(m.id)
                                )}
                                onBlur={formik.handleBlur(
                                  `timetable_entries.days[${day.value}]`
                                )}
                                onChange={(value) => {
                                  formik.setFieldValue(
                                    `timetable_entries.days[${day.value}]`,
                                    value.map((v) => v.id)
                                  );
                                  // handleDayChange(day?.value);
                                }}
                                menuPlacement="auto"
                              />
                              {formik.touched?.timetable_entries?.days?.[
                                day.value
                              ] &&
                                formik.errors?.timetable_entries?.days?.[
                                  day.value
                                ] && (
                                  <span className="custom-error">
                                    {
                                      formik.errors?.timetable_entries?.days?.[
                                        day.value
                                      ]
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {/* {formik.touched?.timetable_entries && formik.errors?.timetable_entries && (
                <span className="custom-error">
                  {formik.errors?.timetable_entries}
                </span>
              )} */}
              </div>
              
            </div>
          </div>
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.submitForm}
            disabled={!formik.isValid}

          >
            {`Assign`}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TimeScheduleForm;
