import { useFormik } from "formik";
import * as Yup from "yup";
import { useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  updateSubAdmin,
  passwordReset,
  viewSubAdminProfile,
} from "../../../../store/slices/SubAdmins/subAdminsSlice";
import { emailRegExp, phoneRegExp } from "../../../../utils/functions/table";
import { updateConfig } from "../../../../store/slices/Global";
import countryData from "../../../../utils/components/countryCode";

const useProfileEdit = ({ setProfileViewVisible, userData }) => {
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState("");
  const profilefileInputRef = useRef(null);
  // const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(true);

  const [imagePreview, setImagePreview] = useState(
    userData !== "" ? userData?.image_url : ""
  );

  const cuntryCodeOptions = useMemo(
		() =>
			countryData?.map((opt) => {
				return {
					value: opt.value,
					label: opt.label,
					title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
					code: opt.code,
				};
			}),
		[]
	);

  const [showExistingPassword, setShowExistingPassword] = useState(true);

  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();

  var validationSchemaProfileEdit = Yup.object({
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
      
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits")
      .required("*Required"),

  });

  var validationSchemaPasswordChange = Yup.object({
    current_password: Yup.string().required("Current password is required"),
    password: Yup.string().required("New password is required"),
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const formikEditUserData = useFormik({
    initialValues: {
      email: userData?.email,
      dial_code:userData?.dial_code,
      phoneNumber: userData?.mobile_number,
    },

    validationSchema: validationSchemaProfileEdit,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const formikEditPassword = useFormik({
    initialValues: {
      current_password: "",
      password: "",
    },

    validationSchema: validationSchemaPasswordChange,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleSubmitPasswordChange(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      let obj = {
        // first_name: userData?.first_name,
        // last_name: userData?.last_name,
        // phone_number: values.phoneNumber,
        // email: values.email,
        // gender: userData?.gender,
        // role_id: userData?.role_id,
        // user_id: userData?._id,
        // country_code: userData?.country_code,
        first_name: userData.first_name,
        last_name: userData.last_name ? userData.last_name : "",
        mobile_number: values.phoneNumber,
        dial_code: values.dial_code,
        email: userData.email,
      };

      if (userData.profile?.name) {
        obj.image = userData.profile;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      dispatch(updateSubAdmin({ id: userData.id, data: formData }))
        .unwrap()
        .then((data) => {
          dispatch(
            updateConfig((state) => {
              state.isProfileViewVisible = false;
            })
          );

          dispatch(viewSubAdminProfile());
          resetForm();
          toast.success("Your details has been updated successfully.");
        })
        .catch((err) => {
          toast.error("Failed to Update");
          // if (err?.message?.groom_name?.[0] !== "") {
          //   setFieldError("groomName", err?.message?.groom_name?.[0]);
          // }
        });
    } catch (error) {}
  };

  const handleSubmitPasswordChange = async (
    values,
    resetForm,
    setFieldError
  ) => {
    try {
      try {
        let obj = {
          current_password: values.current_password,
          password: values.password,
        };

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        dispatch(passwordReset(formData))
          .unwrap()
          .then((data) => {
            if (data?.data?.success) {
              dispatch(
                updateConfig((state) => {
                  state.isProfileViewVisible = false;
                })
              );
              resetForm();
              toast.success("Your password has been updated successfully.");
            }
          })
          .catch((err) => {
            if (err?.message?.password?.[0] !== "") {
              setFieldError("current_password", err?.message?.password?.[0]);
            }
          });
      } catch (error) {}
    } catch (error) {
      toast.success("Failed to Update!");
    }
  };

  const handleShowExistingPassword = (e) => {
    e.preventDefault();
    setShowExistingPassword(!showExistingPassword);
    var x = document.getElementById("current_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowNewPassword = (e) => {
    e.preventDefault();
    setShowNewPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formikEditUserData.setFieldValue("profile", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
        formikEditUserData.handleSubmit();
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
    }
  };

  const handlePhoneNumberChange =()=>{

  }

  return {
    message,
    setLoading,
    setMessage,
    setOptions,
    cuntryCodeOptions,
    imagePreview,
    profilefileInputRef,
    formikEditUserData,
    formikEditPassword,
    loading,
    showExistingPassword,
    showNewPassword,
    handlePhoneNumberChange,
    handleProfileImage,
    handleShowExistingPassword,
    handleShowNewPassword,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
    isDataLoading,
    setIsDataLoading,
    userData,
  };
};

export default useProfileEdit;
