import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("filter")}${getParams("sort_by")}sort_order=${
          params?.sort_order || "desc"
        }&length=${params?.length || "10"}&${getParams("search")}page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const email = createApi({
  reducerPath: "emailApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Email"],
  endpoints: (builder) => ({
    getEmailData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/email-template`,
      }),
      providesTags: ["Email"],
    }),
    getBasicData: builder.query({
        query: (values) => ({
          method: "get",
          params: values,
          endpoint: `/v1/email-template/email-scenario`,
        }),
        providesTags: ["Email"],
      }),
  }),
});

export const { useGetEmailDataQuery,useGetBasicDataQuery} = email;
