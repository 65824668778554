import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { useGetBasicDataQuery } from "../../../../store/queries/email";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import {
  createEmail,
  updateConfig,
  updateEmail,
} from "../../../../store/slices/Email/emailSlice";

const useCreateEmail = ({ closeModal, refetch }) => {
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetBasicDataQuery({});

  const { selectedItemsDetails, is_edit } = useSelector((state) => state.email);
  const dispatch = useDispatch();

  const handleContentChange = (editorsState) => {
    formik.setFieldValue("editorContent", editorsState);
  };

  let YupValidationObject = Yup.object().shape({
    email_scenario_id: Yup.string().required("*Required"),
    name: Yup.string().required("*Required"),
    subject: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      email_scenario_id:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.email_scenario_id
          : "",

      name: selectedItemsDetails !== "" ? selectedItemsDetails?.name : "",

      subject: selectedItemsDetails !== "" ? selectedItemsDetails?.subject : "",
      // editorContent:EditorState.createEmpty(),
    },

    validationSchema: YupValidationObject,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};

      // Convert editor content to plain text
      const plainText = values.editorContent.getCurrentContent().getPlainText();

      // Check if the content is empty or only contains whitespace
      if (!plainText.trim()) {
        errors.editorContent = "*Editor content cannot be empty";
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      const contentState = values.editorContent.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const plainText = draftToHtml(contentRaw);

      
      let obj = {
        email_scenario_id: values.email_scenario_id,
        name: values.name,
        subject: values.subject,
        content: plainText,
      };

      if (is_edit === true) {
        dispatch(updateEmail({ id: selectedItemsDetails.id, data: obj })).then(
          (response) => {
            if (response?.payload?.status_code === 200) {
              resetForm();
              closeModal?.();
              refetch?.();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );

              toast.success(response?.payload?.message);
            } else if (response?.payload?.status_code === 400) {
              formik.setErrors(response?.payload?.message);
            } else toast.error(response?.payload?.message);
          }
        );
      } else {
        dispatch(createEmail(obj)).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            refetch?.();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const defaultHtmlContent = selectedItemsDetails.content;

  const sanitizedHtml = DOMPurify.sanitize(defaultHtmlContent);
  const contentState = ContentState.createFromBlockArray(
    convertFromHTML(sanitizedHtml)
  );
  const editorStates = EditorState.createWithContent(contentState);

  useEffect(() => {
    if (!formik.values.editorContent) {
      formik.setFieldValue("editorContent", editorStates);
    }
  }, [editorStates]);

  return {
    formik,
    selectedItemsDetails,
    optionsData,
    editorStates,
    handleContentChange,
  };
};

export default useCreateEmail;
