import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  is_edit: false,
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  transferNote: "",
  IDs: {},
  selectedItemsDetails: {},
};
export const getEmailDataByID = createAsyncThunk(
  "v1/email-template/show",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/email-template/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })
  export const createEmail= createAsyncThunk(
    "v1/email-template/store",
    async (data, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post("/v1/email-template/store", data);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const updateEmail = createAsyncThunk(
    "v1/email-template/update",
    async ({ id, data }, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post(`/v1/email-template/update/${id}`, data);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  
  },
  extraReducers: (builder) => {
    builder.addCase(getEmailDataByID.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getEmailDataByID.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    })
    .addCase(getEmailDataByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  }
  
});

export const { updateConfig } = emailSlice.actions;

export default emailSlice.reducer;
