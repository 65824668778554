import React from "react";
import ReactDOM from "react-dom/client";

import { configureStore } from "@reduxjs/toolkit";
import App from "./components/App";
import { Provider } from "react-redux";
import appReducer from "./store";
import { enquires } from "./store/queries/enquires";
import { settings } from "./store/queries/settings";
import { branches } from "./store/queries/branches";
import { courses } from "./store/queries/courses";
import { faculties } from "./store/queries/faculties";
import { sales } from "./store/queries/sales";
import { invoices } from "./store/queries/invoices";
import { subAdmins } from "./store/queries/subAdmins";
import { actionLogs } from "./store/queries/actionLogs";
import { students } from "./store/queries/students";
import { email } from "./store/queries/email";
import { notificationRtk } from "./store/queries/notifications";
import { dashboard } from "./store/queries/Dashboard";
import { timeTable } from "./store/queries/timeTable";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      enquires.middleware,
      students.middleware,
      settings.middleware,
      branches.middleware,
      courses.middleware,
      faculties.middleware,
      sales.middleware,
      actionLogs.middleware,
      invoices.middleware,
      subAdmins.middleware,
      email.middleware,
      notificationRtk.middleware,
      dashboard.middleware,
      timeTable.middleware
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
