import {
  Actions,
  Header,
  ModalLayout,
  NavGroup,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
  OffCanvasLayout,
  ConfirmationBox,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import { ProfileCard } from "../../Global/ProfileCard";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import useStudentsDetailLayout from "./useStudentsDetailLayout";
import profileStyle from "./studentsDetailLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";

import StudentsForm from "../StudentsListing/StudentsForm";
import Assets from "../../../assets/Assets";
import { SimpleImageSlider } from "../../Global/SimpleImageSlider";
import HeaderActions from "../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../pages/Layouts/CommonLayout/useCommonLayout";
import CourseAssign from "../CourseAssign";

const StudentsDetailLayout = ({ navigation }) => {
  const {
    ProfileState,
    activeProfile,
    Studentstate,
    closeModal,
    isFetching,
    profileData,
    basicDetails,
    hasUpdatePermission,
    handleEditClick,
    closeEditOffcanvas,
  } = useStudentsDetailLayout();

  const { navigations, globalState } = useCommonLayout();

  function getPackageStatus(expiryDate) {
    const [day, month, year] = expiryDate.split("-");
    const formattedExpiryDate = `${year}-${month}-${day}`;

    const currentDate = new Date();
    const packageExpiry = new Date(formattedExpiryDate);

    if (currentDate < packageExpiry) {
      return "Package Expiry";
    } else {
      return "Package Expired on";
    }
  }

 

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      {/* <SimpleImageSlider
                        // data={imageSlider?.sort(
                        //   (a, b) => b.isPrimary - a.isPrimary
                        // )}
                        width={329.7}
                        height={329.55}
                        // handleClick={handleProfileClick}
                        isPremium={profileData?.data?.premium_status}
                        status={profileData?.data?.status || 1}
                      /> */}

                      <div className={profileStyle.profile}>
                        <Image
                          src={`${
                            profileData?.data?.rows?.image_url ??
                            Assets.GENDER_IMAGE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      <SimpleTextDetails
                        title={`${profileData?.data?.rows?.full_name ?? ""}`}
                        designation={`${
                          profileData?.data?.rows?.stream?.name ?? ""
                        }`}
                        uId={profileData?.data?.rows?.registration_no ?? ""}
                        extraClassNames={`pro-mt-4 pro-mb-2 ${profileStyle.profile_name_wrap}`}
                      />
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <MultiColumnTable
                        title={"Basic Details"}
                        data={basicDetails}
                        extraClassNames={`pro-mb-5`}
                        editIcon={
                          hasUpdatePermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : (
                            false
                          )
                        }
                        handleEdit={handleEditClick}
                      />
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          <OffCanvasLayout
          show={ProfileState.showCourseCreateModal}
          handleClose={closeModal}
          title={"Assign New Course"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <CourseAssign
            isStickyFooter
            showCreateModal={ProfileState.showCourseCreateModal}
            profileState={profileData}
            // optionsData={optionsData}
            closeModal={closeModal}
            // enquiryViewData={enquiryViewData}
            // hasEditPermission={hasEditPermission}
          />
        </OffCanvasLayout>
          <OffCanvasLayout
            show={Studentstate.showCreateModal}
            handleClose={closeEditOffcanvas}
            title={`${"Update Students"}`}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
            backdrop="static"
          >
            <StudentsForm closeModal={closeEditOffcanvas} />
          </OffCanvasLayout>

          {/* main container */}

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <Outlet context={{ activeProfile }} />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default StudentsDetailLayout;
