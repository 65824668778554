import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React, { useState } from "react";
import Select from "react-select";
import Style from "./salesForm.module.scss";
import useSalesForm from "./useSalesForm";
import {
  capitalizeInitial,
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import { format, parse } from "date-fns";

const SalesForm = ({
  refetch,
  optionsData,
  dropDownData,
  hasUpdatePermission,
  closeModal,
  isStickyFooter,
}) => {
  const {
    formik,
    imageCoverPreview,
    handleCoverImage,
    selectedItemsDetails,
    profilefileInputRef,
    cuntryCodeOptions,
    showPassword,
    showConfirmPassword,
    handleShowPassword,
    handleShowConfirmPassword,
    stateRef,
    handlePhoneNumberChange,
    handleCountryId,
    options,
    selectedOption,
    handleChange,
  } = useSalesForm({ refetch, closeModal });



  const states = optionsData?.data?.rows?.filter(
    (p) => p?.id === formik?.values?.country_id
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(() => true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(() => false);
  };

  let maxDate = new Date();

  const reverseDateString = (dateString) => {
    const parts = dateString?.split("-");
    const reversedDateString = parts?.[0] + "-" + parts?.[1] + "-" + parts?.[2];
    return reversedDateString;
  };
  let dateStr = reverseDateString(formik.values.date_of_birth);
  let dateJoinStr = reverseDateString(formik.values.join_date);

  const handleDobChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("date_of_birth", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  const handleJoinChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("join_date", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"First name*"}
        type="text"
        id="first_name
          "
        name="first_name
          "
        className={`pro-input lg ${
          formik.errors.first_name && formik.touched.first_name && "error"
        }`}
        {...formik.getFieldProps("first_name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.first_name && formik.touched.first_name}
        errorMessage={formik.errors.first_name}
      />

      <Input
        label={"Last name"}
        type="text"
        id="last_name
          "
        name="last_name
          "
        className={`pro-input lg ${
          formik.errors.last_name && formik.touched.last_name && "error"
        }`}
        {...formik.getFieldProps("last_name")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.last_name && formik.touched.last_name}
        errorMessage={formik.errors.last_name}
      />

      <Input
        label={"Email address*"}
        type="text"
        id="email"
        name="email"
        placeholder="name@example.com"
        className={`pro-input lg ${
          formik.errors.email && formik.touched.email && " error"
        }`}
        {...formik.getFieldProps("email")}
        error={formik.errors.email && formik.touched.email}
        errorMessage={formik.errors.email}
      />

      <div className="input-wrap pro-mb-4">
        <div className="input-country-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Mobile number*
          </label>
          <div className="input-main-wrap ">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="dial_code"
                options={cuntryCodeOptions}
                // getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                menuIsOpen={menuIsOpen}
                formatOptionLabel={({ label, title }) =>
                  menuIsOpen ? title : label
                }
                className={` multi-select country-select ${
                  formik.touched.dial_code &&
                  formik.errors.dial_code &&
                  " error"
                }`}
                value={cuntryCodeOptions?.filter(
                  (p) =>
                    p.value?.toString() === formik.values.dial_code?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={() => formik.handleBlur("dial_code")}
                onChange={(value) =>
                  formik.setFieldValue("dial_code", value?.value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="mobile_number"
              name="mobile_number"
              className={`pro-input lg ${
                formik.errors.mobile_number &&
                formik.touched.mobile_number &&
                " error"
              }`}
              {...formik.getFieldProps("mobile_number")}
              error={
                formik.errors.mobile_number && formik.touched.mobile_number
              }
              errorMessage={formik.errors.mobile_number}
              onBlur={() => formik.handleBlur("mobile_number")}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              // {...formik.getFieldProps("mobile_number")}
            />
          </div>
          {formik.touched.mobile_number && formik.errors.mobile_number && (
            <span className="error-text">{formik.errors.mobile_number}</span>
          )}
        </div>
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Job Type*
        </label>
        <Select
          id="job_type_id"
          name="job_type_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.job_type_id && formik.touched.job_type_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={dropDownData?.data?.jobTypes}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={dropDownData?.data?.jobTypes?.filter(
            (m) => formik.values.job_type_id === m?.id
          )}
          onChange={(value) => formik.setFieldValue("job_type_id", value.id)}
        />
        {formik.touched.job_type_id && formik.errors.job_type_id && (
          <span className="error-text">{formik.errors.job_type_id}</span>
        )}
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Qualification*
        </label>
        <Select
          id="qualification_id"
          name="qualification_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.qualification_id &&
            formik.touched.qualification_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={dropDownData?.data?.qualifications}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={dropDownData?.data?.qualifications?.filter(
            (m) => formik.values.qualification_id === m?.id
          )}
          onChange={(value) =>
            formik.setFieldValue("qualification_id", value.id)
          }
        />
        {formik.touched.qualification_id && formik.errors.qualification_id && (
          <span className="error-text">{formik.errors.qualification_id}</span>
        )}
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Branch*
        </label>
        <Select
          id="branch_id"
          name="branch_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.branch_id && formik.touched.branch_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={dropDownData?.data?.branches}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.user?.full_name}
          value={dropDownData?.data?.branches?.filter(
            (m) => formik.values.branch_id === m?.id
          )}
          onChange={(value) => formik.setFieldValue("branch_id", value.id)}
        />
        {formik.touched.branch_id && formik.errors.branch_id && (
          <span className="error-text">{formik.errors.branch_id}</span>
        )}
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Gender*
        </label>
        <Select
          id="gender"
          name="gender"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.gender && formik.touched.gender && " error"
          }`}
          classNamePrefix="pro-input"
          onChange={handleChange}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          value={selectedOption ? selectedOption : formik?.values?.["gender"]}
          onBlur={formik.handleBlur("gender")}
          options={options}
        />
        {formik.touched.gender && formik.errors.gender && (
          <span className="error-text">{formik.errors.gender}</span>
        )}
      </div>

      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of Birth*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={formik.values.date_of_birth === "" ? "yyyy-MM-dd" : dateStr}
            type={"date"}
            max={maxDate.toLocaleDateString("fr-ca")}
            className={`pro-input lg ${
              formik.touched.date_of_birth &&
              formik.errors.date_of_birth &&
              "error"
            }`}
            onChange={handleDobChange}
            onBlur={formik.handleBlur("date_of_birth")}
            error={formik.touched.date_of_birth && formik.errors.date_of_birth}
            errorMessage={formik.errors.date_of_birth}
          />
        </div>
      </div>

      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of Join*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={formik.values.join_date === "" ? "yyyy-MM-dd" : dateJoinStr}
            type={"date"}
            className={`pro-input lg ${
              formik.touched.join_date && formik.errors.join_date && "error"
            }`}
            onChange={handleJoinChange}
            onBlur={formik.handleBlur("join_date")}
            error={formik.touched.join_date && formik.errors.join_date}
            errorMessage={formik.errors.join_date}
          />
        </div>
      </div>

      {selectedItemsDetails === "" && (
        <div className="p-re">
          <Input
            label={"Password*"}
            type="password"
            id="password"
            autoComplete="new-password"
            name="password"
            className={`pro-input lg ${
              formik.errors.password && formik.touched.password && " error"
            }`}
            {...formik.getFieldProps("password")}
            error={formik.errors.password && formik.touched.password}
            errorMessage={formik.errors.password}
          />
          {showPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
        </div>
      )}

      {selectedItemsDetails === "" && (
        <div className="p-re">
          <Input
            label={"Re-enter password*"}
            type="password"
            id="confirm_password"
            autoComplete="new-password"
            name="confirm_password"
            className={`pro-input lg ${
              formik.errors.confirm_password &&
              formik.touched.confirm_password &&
              " error"
            }`}
            {...formik.getFieldProps("confirm_password")}
            error={
              formik.errors.confirm_password && formik.touched.confirm_password
            }
            errorMessage={formik.errors.confirm_password}
          />
          {showConfirmPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowConfirmPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowConfirmPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
        </div>
      )}

      <Input
        label={"Address 1*"}
        type="text"
        id="address_one
          "
        name="address_one
          "
        className={`pro-input lg ${
          formik.errors.address_one && formik.touched.address_one && " error"
        }`}
        {...formik.getFieldProps("address_one")}
        error={formik.errors.address_one && formik.touched.address_one}
        errorMessage={formik.errors.address_one}
      />

<Input
        label={"Address 2"}
        type="text"
        id="address_two
          "
        name="address_two
          "
        className={`pro-input lg ${
          formik.errors.address_two && formik.touched.address_two && " error"
        }`}
        {...formik.getFieldProps("address_two")}
        error={formik.errors.address_two && formik.touched.address_two}
        errorMessage={formik.errors.address_two}
      />

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Country*
        </label>
        <Select
          id="country_id"
          name="country_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.country_id && formik.touched.country_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.data?.rows}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.country_name}
          value={optionsData?.data?.rows?.filter(
            (m) => formik.values.country_id === m?.id
          )}
          onChange={(value) => handleCountryId(value)}
        />
        {formik.touched.country_id && formik.errors.country_id && (
          <span className="error-text">{formik.errors.country_id}</span>
        )}
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          State*
        </label>
        <Select
          id="state_id"
          name="state_id"
          isClearable={false}
          ref={stateRef}
          isDisabled={states[0]?.states ? false : true}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.state_id &&
            formik.touched.state_id &&
            states?.[0]?.states &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={states[0]?.states}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.state_name}
          value={states?.[0]?.states.filter(
            (m) => formik.values.state_id === m?.id
          )}
          onChange={(value) => {
            formik.setFieldValue("state_id", value.id || null);
          }}
        />
        {formik.touched.state_id &&
          formik.errors.state_id &&
          states?.[0]?.states && (
            <span className="error-text">{formik.errors.state_id}</span>
          )}
      </div>

      <Input
        label={"City*"}
        type="text"
        id="city
          "
        name="city
          "
        className={`pro-input lg ${
          formik.errors.city && formik.touched.city && " error"
        }`}
        {...formik.getFieldProps("city")}
        error={formik.errors.city && formik.touched.city}
        errorMessage={formik.errors.city}
      />

      <Input
        label={"Zipcode"}
        type="text"
        id="zipcode"
        name="zipcode
          "
        className={`pro-input lg ${
          formik.errors.zipcode && formik.touched.zipcode && " error"
        }`}
        {...formik.getFieldProps("zipcode")}
        error={formik.errors.zipcode && formik.touched.zipcode}
        errorMessage={formik.errors.zipcode}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleCoverImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.image?.name !== undefined
                  ? limitStrLength(formik?.values?.image?.name, 30)
                  : selectedItemsDetails !== "" &&
                    selectedItemsDetails?.user?.original_image_name
                  ? selectedItemsDetails?.user?.original_image_name
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`sales - 01`}
          />
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default SalesForm;
