import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { enquires } from "../../../store/queries/enquires";
import { students } from "../../../store/queries/students";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Enquiries/enquirySlice";
import { updateConfig as studentProfileUpdateConfig } from "../../../store/slices/Students/studentsProfileSlice";

const useKanboardListing = ({
  data,
  updateData,
  studentCourse,
  refetchDataContainer,
}) => {
  const [characters, setCharacters] = useState([]);
  const [removeDraggable, setRemoveDraggable] = useState(false);

  const [isDropInProgress, setIsDropInProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setCharacters(data);
  }, [data]);

  const onDragStart = () => {
    setIsDropInProgress(true);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      setIsDropInProgress(false);
      return;
    }
    if (result.source.droppableId === result.destination.droppableId) {
      setIsDropInProgress(false);
      // const destinationStatus = parseInt(
      //   result.destination.droppableId.slice(7),
      //   10
      // );
      // const movedItemId = parseInt(result.draggableId.slice(5), 10);
      // const movedItem = characters.find((item) => item.id === movedItemId);
      // if (!movedItem) return;
      // const updatedCharacters = characters.filter(
      //   (item) => item.id !== movedItemId
      // );
      // const insertIndex = result.destination.index;
      // updatedCharacters.splice(insertIndex, 0, {
      //   ...movedItem,
      //   status: destinationStatus,
      // });
      // setCharacters(updatedCharacters);
    } else {
      setRemoveDraggable(true);
      const items = JSON.parse(JSON.stringify(characters));

      const [reorderedItem] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderedItem);

      const targetObject = items.find(
        (item) => item.id === parseInt(result?.draggableId?.slice(5))
      );

      if (targetObject) {
        targetObject.status = parseInt(
          result?.destination?.droppableId?.slice(7),
          10
        );
      }

      const updatedCharacters = items.filter(
        (item) => item.status === targetObject.status
      );
      // .sort((a, b) => a.id - b.id);

      const remainingCharacters = items.filter(
        (item) => item.status !== targetObject.status
      );

      const newCharacters = [...remainingCharacters, ...updatedCharacters];

      const obj = {
        status: parseInt(result?.destination?.droppableId?.slice(7), 10),
        id: parseInt(result?.draggableId?.slice(5)),
        email_scenario_id: 2,
      };
      if (studentCourse) {
        delete obj.email_scenario_id;
        dispatch(
          studentProfileUpdateConfig((state) => {
            state.queryData.data = newCharacters;
          })
        );
      } else {
        dispatch(
          updateConfig((state) => {
            state.queryData.data = newCharacters;
          })
        );
      }

      setCharacters(newCharacters);
      updateData(obj).then((response) => {
        if (response?.data?.status_code === 200) {
          // toast.success("Successfully updated status");
          setIsDropInProgress(false);
          if (studentCourse) {
            dispatch(students.util.invalidateTags(["profileCourse"]));
            refetchDataContainer();
          } else {
            dispatch(enquires.util.invalidateTags(["enquiryStatus"]));
            if (
              parseInt(result?.destination?.droppableId?.slice(7), 10 === 6)
            ) {
              dispatch(enquires.util.invalidateTags(["enroll"]));
            }
          }
        } else {
          setIsDropInProgress(false);
          setCharacters(JSON.parse(JSON.stringify(characters)));
          toast.error("Failed to update status");
        }
        setRemoveDraggable(false);
      });
    }
  };

  return {
    characters,
    isDropInProgress,
    removeDraggable,
    onDragEnd,
    onDragStart,
  };
};

export default useKanboardListing;
