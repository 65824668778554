import style from "./dashboardCard.module.scss";
const DashboardCard = ({ title, data, icon, active, transactions }) => {
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner}`}>
        <div className={`${style.inner_heading} pro-mb-3`}>
          <div className={`${style.icon}`}>
            <span className="material-symbols-outlined">{icon}</span>
          </div>
          <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium pro-ps-2">
            {data?.month ? `${title}(${data?.month}) ` : title}
          </h6>
        </div>

        <div className={`${style.body} pro-d-flex`}>
          <h3 className={`pro-ttl pro-mb-0 pro-fw-bold ${style.total_value}`}>
            {typeof data?.current_month === "string" ? (
              <span className="material-symbols-outlined">currency_rupee</span>
            ) : (
              <></>
            )}
            {data?.current_month >= 0 ? data?.current_month : data}
          </h3>
          {data?.comparison_status && (
            <span
              className={`${
                data?.comparison_status === "greater"
                  ? style.increment
                  : data?.comparison_status === "lower"
                  ? style.decrement
                  : ""
              } ${
                style.growth_percentage
              } pro-align-self-center pro-font-xs pro-fw-medium`}
            >
              {data?.percentage_difference}
            </span>
          )}
        </div>

        {data?.comparison_status && (
          <div
            className={`${style.footer} pro-d-flex pro-badge pro-mt-4 badge-danger pro-me-auto pro-items-center`}
          >
            <h6
              className={`pro-ttl pro-mb-0 pro-fw-bold pro-font-xs ${style.last_month}`}
            >
              {"Last Month :"}
            </h6>

            <span
              className={`${style.growth_percentage} pro-align-self-center pro-font-xs pro-fw-medium pro-ps-1`}
            >
              {typeof data?.current_month === "string" ? (
                <span className="material-symbols-outlined">
                  currency_rupee
                </span>
              ) : (
                <></>
              )}
              {data?.previous_month}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
