import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetRolesDataQuery } from "../../../store/queries/settings";

import { toast } from "react-toastify";
import { useGetEmailDataQuery } from "../../../store/queries/email";
import {
  getEmailDataByID,
  updateConfig,
} from "../../../store/slices/Email/emailSlice";

const useEmail = () => {
  const parse = require("html-react-parser");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.email);
  const {
    data: emailData = {},
    isFetching,
    isSuccess,
    isLoading,
    refetch,
  } = useGetEmailDataQuery({
    length: state.currentPageSize,
    page: state.currentPage,
  });
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [showModal, setShowModal] = useState(false);

  const hasCreatePermission = useMemo(() => {
    let permission = emailData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [emailData]);
  const hasEditPermission = useMemo(() => {
    let permission = emailData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [emailData]);
  const hasViewPermission = useMemo(() => {
    let permission = emailData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
  }, [emailData]);
  const hasDeletePermission = useMemo(() => {
    let permission = emailData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [emailData]);

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.selectedItemsDetails = {};
        state.is_edit = false;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedItemsDetails = {};
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  useEffect(() => {
    if (state.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [state.clearSelection]);

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleEdit = (data) => {
    // setSelectedId(data?.[0]);
    dispatch(getEmailDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };
  // const handleDelete = async (ids) => {
  //   dispatch(deleteItemFromTable(ids[0]))
  //     .unwrap()
  //     .then((result) => {
  //       if (result?.data?.message === "Success") {
  //         toast.success("User Type deleted successfully");
  //       } else {
  //         toast.error(result?.data?.message);
  //       }
  //       if (result.data.data) {
  //         refetch();
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      subject: (field, data) => <p>{parse(data[field])}</p>,
      content: (field, data) => <p>{parse(data[field])}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  return {
    emailData,
    state,
    currentPage: state.currentPage,
    handlePagination,
    handlePageSize,
    isFetching,
    paginationOptions,
    showModal,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    hasViewPermission,
    handleCreateClick,
    handleEdit,
    refetch,
    getRow,
    closeModal,
  };
};

export default useEmail;
