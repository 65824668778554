import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("filter")}${getParams("sort_by")}filters=${
          params?.filter || "all"
        }&sort_order=${params?.sort_order || "desc"}&length=${
          params?.length || "10"
        }&${getParams("search")}page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const subAdmins = createApi({
  reducerPath: "subAdminsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["SubAdmins", "User Type"],
  endpoints: (builder) => ({
    getSubAdminsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/sub-admin`,
      }),
      providesTags: ["SubAdmins"],
    }),
    getBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/country`,
      }),
      providesTags: ["SubAdmins"],
    }),
    getBasicOptionData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/settings/fetch-basic-apis`,
      }),
      providesTags: ["SubAdmins"],
    }),
    getUserTypeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/user-type/list-all`,
      }),
      providesTags: ["User Type"],
    }),
    updateSubAdminListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `/v1/table-permission-create`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["SubAdmins"],
    }),
    getBranchData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/branch/list-all`,
      }),
    }),
  }),
});

export const {
  useGetSubAdminsDataQuery,
  useGetBasicDataQuery,
  useGetUserTypeDataQuery,
  useGetBasicOptionDataQuery,
  useUpdateSubAdminListFieldsDataMutation,
  useGetBranchDataQuery,
} = subAdmins;
