import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetBranchDataQuery,
  useGetCourseListDataQuery,
} from "../../../../store/queries/courses";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getStreams } from "../../../../store/slices/Students/studentsSlice";
import {
  updateConfig,
  resetConfig,
} from "../../../../store/slices/Students/studentFilterSlice";
import { updateConfig as filterUpdateConfig } from "../../../../store/slices/Students/studentsSlice";
import { toast } from "react-toastify";

const useFilter = () => {
  const dispatch = useDispatch();
  const { data: courseData = {} } = useGetCourseListDataQuery();
  const { data: branchData = {} } = useGetBranchDataQuery();
  const { statusFilterData, streamData } = useSelector(
    (state) => state.students
  );
  useEffect(() => {
    dispatch(getStreams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const studentFilterState = useSelector((state) => state.studentsFilter);
  const validation = Yup.object({
    stream_id: Yup.string().nullable(),
    course_status: Yup.string().nullable(),
    course_id: Yup.string().nullable(),
    branch_id: Yup.string().nullable(),
    join_start_date: Yup.string().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      stream_id: studentFilterState?.stream_id ?? "",
      course_status: studentFilterState?.course_status ?? "",
      course_id: studentFilterState?.course_id ?? "",
      branch_id: studentFilterState?.branch_id ?? "",
      join_start_date: studentFilterState?.join_start_date ?? "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const {
        stream_id,
        course_status,
        course_id,
        branch_id,
        join_start_date,
      } = values;
      if (
        stream_id === "" &&
        course_status === "" &&
        course_id === "" &&
        branch_id === "" &&
        join_start_date === ""
      ) {
        toast.error(`All fields are empty`);
      } else {
        dispatch(
          updateConfig((state) => [
            (state.branch_id = values?.branch_id),
            (state.course_id = values?.course_id),
            (state.course_status = values?.course_status),
            (state.stream_id = values?.stream_id),
            (state.join_start_date = values?.join_start_date),
          ])
        );
        handleClose();
      }
    },
  });
  const handleClose = () => {
    dispatch(
      filterUpdateConfig((state) => {
        state.filterShow = false;
      })
    );
  };
  const handleReset = () => {
    dispatch(resetConfig());
    handleClose();
  };
  let branchList = branchData?.data?.map((item) => {
    return {
      label: item?.branch_name,
      value: item?.id,
    };
  });
  let courseObj = courseData?.data?.filter((item) => {
    return item?.id === formik?.values?.course_id;
  });
  let statusObj = statusFilterData?.filter(
    (item) => item?.statusType === formik?.values?.course_status
  );
  let streamObj = streamData?.rows?.filter(
    (item) => item?.id === formik?.values?.stream_id
  );
  let branchObj = branchList?.filter(
    (item) => item?.value === formik?.values?.branch_id
  );
  return {
    formik,
    courseData,
    courseObj,
    statusObj,
    branchList,
    streamObj,
    streamData,
    branchObj,
    statusData: statusFilterData,
    handleReset,
  };
};

export default useFilter;
