import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useSettings = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);

  const drawerMenu = [
    {
      title: "User Types",
      permission: "User Types",
      link: "/settings/roles",
      iconAsset: "DrawerIcon3",
      icon: (
        <span className="material-symbols-outlined x4">workspace_premium</span>
      ),
      active: checkIfActiveRoute("/settings/roles"),
    },
    {
      title: "Staff",
      link: "/settings/sub-admins",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/settings/sub-admins", true),
    },
    {
      title: "Branches",
      link: "/settings/branches",
      icon: (
        <span className="material-symbols-outlined x4"> network_node </span>
      ),
      active: checkIfActiveRoute("/settings/branches", true),
    },
    {
      title: "Invoices",
      link: "/settings/invoices",
      icon: <span className="material-symbols-outlined x4"> payments </span>,
      active: checkIfActiveRoute("/settings/invoices", true),
    },
    {
      title: "Email",
      permission: "Email",
      link: "/settings/email",
      iconAsset: "DrawerIcon3",
      icon: <span className="material-symbols-outlined x4">mail</span>,
      active: checkIfActiveRoute("/settings/email"),
    },
    {
      title: "Action Logs",
      link: "/settings/action-logs",
      icon: (
        <span className="material-symbols-outlined x4"> manage_history </span>
      ),
      active: checkIfActiveRoute("/settings/action-logs", true),
    },
  ];

  const newDrawerMenuPermission = [
    {
      title: "Appearance",
      link: "/settings/appearance",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">receipt_long</span>,
      active: checkIfActiveRoute("/settings/appearance"),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.modules?.flatMap?.(
      (menu) => [
        // menu.module_name,
        ...menu?.sub_menu_permissions?.map(
          (side_menu) => side_menu?.sub_menu_name
        ),
        // ...menu.permissions?.map((side_menu) => side_menu.name),
      ]
    );
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  const combinedMenuPermissions = [
    ...drawerMenuPermission,
    ...newDrawerMenuPermission,
  ];

  return {
    drawerMenu: combinedMenuPermissions,
  };
};

export default useSettings;
