import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useUpdateNewCourseForm from "./useUpdateNewCourseForm";

const UpdateNewCourseForm = ({ studentData, courseRefetch }) => {
  const {
    formik,
    courseList,
    courseObj,
    dateStartDateStr,
    handleCloseModal,
    handleStartDateChange,
    currencyList,
    profileState,
    handleCourseChange
  } = useUpdateNewCourseForm({ studentData, courseRefetch });

  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Course
          </label>
          <Select
            id="course_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.course_id && formik.errors.course_id && " error"
            }`}
            classNamePrefix="pro-input"
            options={courseList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={courseObj}
            onBlur={formik.handleBlur("course_id")}
            // onChange={(value) => {
            //   formik.setFieldValue("course_id", value?.value ?? "");
            // }}
            onChange={(value) => handleCourseChange(value)}
          />
          {formik.touched.course_id && formik.errors.course_id && (
            <span className="error-text">{formik.errors.course_id}</span>
          )}{" "}
        </div>
        <div className="input-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Course Start Date*
          </label>
          <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
            <Input
              value={
                formik.values.course_start_date === ""
                  ? "yyyy-MM-dd"
                  : dateStartDateStr
              }
              type={"date"}
              className={`pro-input lg ${
                formik.touched.course_start_date &&
                formik.errors.course_start_date &&
                "error"
              }`}
              onChange={handleStartDateChange}
              onBlur={formik.handleBlur("course_start_date")}
              error={
                formik.touched.course_start_date &&
                formik.errors.course_start_date
              }
              errorMessage={formik.errors.course_start_date}
              // disabled={true}
            />
          </div>
        </div>
        <Input
          label={"Weekly Hours*"}
          id={`weekly_hours`}
          name={`weekly_hours`}
          type="text"
          {...formik.getFieldProps(`weekly_hours`)}
          className={`pro-input lg ${
            formik.touched.weekly_hours &&
            formik.errors.weekly_hours &&
            " error"
          }`}
          error={formik.touched.weekly_hours && formik.errors.weekly_hours}
          errorMessage={formik.errors.weekly_hours}
          // disabled={true}
        />
        <Input
          label={"Total Amount*"}
          id={`total_amount`}
          name={`total_amount`}
          type="text"
          {...formik.getFieldProps(`total_amount`)}
          className={`pro-input lg ${
            formik.touched.total_amount &&
            formik.errors.total_amount &&
            " error"
          }`}
          error={formik.touched.total_amount && formik.errors.total_amount}
          errorMessage={formik.errors.total_amount}
          // disabled={true}
        />

<div className="col-md-12">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Currency *
              </label>
              <Select
                name="currency"
                options={currencyList} // replace with your currency options
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                value={currencyList?.filter((m) => formik?.values?.currency_code === m?.value)}
                onChange={(selectedOption) =>
                  formik.setFieldValue(`currency_code`, selectedOption?.value)
                }
                className={`pro-input lg ${
                  formik.touched?.currency_code &&
                  formik.errors?.currency_code &&
                " error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur(`currency_code`)}
                // value={formik.values.currency_code}
                
              />
              {formik.touched?.currency_code &&
                formik.errors?.currency_code && (
                <div className="error-text">{formik.errors?.currency_code}</div>
              )}
            </div>
          </div>


        <Input
          label={"Received Amount*"}
          id={`received_amount`}
          name={`received_amount`}
          type="text"
          {...formik.getFieldProps(`received_amount`)}
          className={`pro-input lg ${
            formik.touched.received_amount &&
            formik.errors.received_amount &&
            " error"
          }`}
          error={formik.touched.received_amount && formik.errors.received_amount}
          errorMessage={formik.errors.received_amount}
          // disabled={true}
        />

<Input
            label={"Received Date*"}
            id={`transaction_date`}
            name={`transaction_date`}
            type="date"
            {...formik.getFieldProps(`transaction_date`)}
            className={`pro-input lg ${
              formik.touched.transaction_date &&
              formik.errors.transaction_date &&
              " error"
            }`}
            error={
              formik.touched.transaction_date && formik.errors.transaction_date
            }
            errorMessage={formik.errors.transaction_date}
          />
        
        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {profileState?.is_edit ? `Update` : `Create`}
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpdateNewCourseForm;
