import { useMemo, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import useObserver from "../../../utils/hooks/useObserver";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { useGetSalesDataQuery, useGetBasicDataQuery, useGetBasicOptionDataQuery, useUpdateSalesListFieldsDataMutation } from "../../../store/queries/sales";
import { updateConfig, deleteSales, getSalesDataByID } from "../../../store/slices/Sales/salesSlice";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const useSale = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.sales);
  const [activeFilter, setActiveFilter] = useState(2);
  const [selectedId, setSelectedId] = useState([]);
  const [skip, setSkip] = useState(true);

  const { showCreateModal, selctedId } = useSelector((state) => state.sales);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const options = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetBasicDataQuery({});

    const { data: dropDownData = {}, isSuccess: isDropDownDataSuccess } =
    useGetBasicOptionDataQuery({});
  const tableRef = useRef(null);
  const debouncedSearchTerm = useDebounce(menuState.search, 1000);

  const {
    data: mainData = {},
    isFetching,
    isSuccess,
    isLoading,
    refetch,
  } = useGetSalesDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: debouncedSearchTerm,
    length: menuState.currentPageSize,
    page: menuState.currentPage,
    is_active: menuState.is_active
  });

  // useEffect(() => {
  //   if (
  //     Object.keys(menuState?.queryData ?? {})?.length === 0 &&
  //     activeFilter === 2
  //   ) {
  //     dispatch(
  //       getEnquiryScrollListData({
  //         sort_by: menuState.sortBy,
  //         sort_order: menuState.sortOrder,
  //         search: menuState.search,
  //         start: menuState.startDate,
  //         end: menuState.endDate,
  //         length: menuState.currentPageSize,
  //         page: menuState.scrollPage,
  //       })
  //     );
  //   }

  //   //eslint-disable-next-line
  // }, [activeFilter]);

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  // const { data: enquiryViewData = {} } = useGetEnquiryViewDataQuery(
  //   {
  //     digital_enquiry_id: selectedId,
  //     page: 1,
  //   },
  //   { skip }
  // );

  // const { data: optionsData = {} } = useGetMemberCreationFormDataQuery({});

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const [updateSalesFields] = useUpdateSalesListFieldsDataMutation();
  // const [updateEnquiryStatus] = useUpdateEnquiryListStatusMutation();

  // useEffect(() => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.selectedItemsDetails = enquiryViewData?.data ?? "";
  //     })
  //   );

  //   // eslint-disable-next-line
  // }, [enquiryViewData]);



  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);
  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [mainData]);
  const hasViewPermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
  }, [mainData]);
  const hasDeletePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
      })
    );
  };
  

  const handleEditAction = (data) => {
    setSkip(() => false);
    setSelectedId(data?.[0]);
    dispatch(getSalesDataByID(data?.[0]))
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };
  const handleEditClick = () => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.selectedItemsDetails = enquiryViewData?.data ?? "";
    //   })
    // );
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      state_id: (feild, data) => (
        <p className="pro-mb-0">{data['state']?.state_name ?? ""}</p>
      ),
      country_id: (feild, data) => (
        <p className="pro-mb-0">{data['country']?.country_name ?? ""}</p>
      ),
      qualification_id: (feild, data) => (
        <p className="pro-mb-0">{data['qualification']?.name ?? ""}</p>
      ),
      job_type_id: (feild, data) => (
        <p className="pro-mb-0">{data['job_type']?.name ?? ""}</p>
      ),
      user_id: (feild, data) => (
        <p className="pro-mb-0">{data['user']?.first_name
        ?? ""}</p>
      ),
      branch_id: (feild, data) => (
        <p className="pro-mb-0">{data['branch']?.user.full_name
        ?? ""}</p>
      ),
      gender: (feild, data) => (
        <p className="pro-mb-0">{data.gender_name
        ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  // const handleViewImage = (data) => {
  //   setShowImageModal(() => true);
  //   setImageData(data?.cover_photo?.url);
  // };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = false;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.invoiceLoading = false;
        state.is_active = 1;
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/payment/mainData");
  };

  const handleLoadMore = useCallback(() => {
    if (isSuccess) {
      dispatch(
        updateConfig((state) => {
          state.scrollPage = mainData?.data?.rows?.enquiries?.current_page + 1;
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, isSuccess, mainData]);

  const { lastElement } = useObserver({
    loading: isLoading,
    hasMore:
      mainData?.data?.rows?.enquiries?.per_page *
        mainData?.data?.rows?.enquiries?.current_page <
      mainData?.data?.rows?.enquiries?.total,
    callback: handleLoadMore,
  });


  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.is_active = selectedOption.value;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteSales(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };
  

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    hasCreatePermission,
    hasDeletePermission,
    isFetching,
    isLoading,

    date,
    optionsData,
    dropDownData,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    // enquiryViewData,
  
    showImageModal,
    imageData,
    hasEditPermission,
    updateSalesFields,
  
    lastElement,
    // updateEnquiryStatus,
    handleEditAction,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    // updateEnquiryFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
		imageData,
    options,
    selectedOption,
    handleChange,
    tableRef,
    setShowDeleteConfirm,
    showDeleteConfirm,
    handleDeleteAction,
    handleDelete
  };
};

export default useSale;
