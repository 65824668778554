import {
  Button,
  // IconText,
  // Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React, { useState } from "react";
import Select from "react-select";
// import Style from "./facultyForm.module.scss";
import useCourseAssign from "./useCourseAssign";

import { format, parse } from "date-fns";
// import CourseModules from "./CourseModules";

const CourseAssign = ({ profileState, closeModal, isStickyFooter }) => {
  const {
    formik,
    optionsData,
    // facultyData,
    // profileData,
    // imageCoverPreview,
    // handleCoverImage,
    // selectedItemsDetails,
    // profilefileInputRef,
    // cuntryCodeOptions,
    // showPassword,
    // showConfirmPassword,
    // handleShowPassword,
    // handleShowConfirmPassword,
    // stateRef,
    // handlePhoneNumberChange,
    // handleCountryId,
    // options,
    // selectedOption,
    // handleChange,
    // moduleList,
    handleCourseChange,
    // facultyList,
  } = useCourseAssign({ closeModal, profileState });

  // const [menuIsOpen, setMenuIsOpen] = useState(false);

  // const handleMenuOpen = () => {
  //   setMenuIsOpen(() => true);
  // };

  // const handleMenuClose = () => {
  //   setMenuIsOpen(() => false);
  // };

  // let maxDate = new Date();

  const reverseDateString = (dateString) => {
    const parts = dateString.split("-");
    const reversedDateString = parts[0] + "-" + parts[1] + "-" + parts[2];
    return reversedDateString;
  };

  let dateJoinStr = reverseDateString(formik.values.course_start_date);

  const handleJoinChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("course_start_date", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name"}
        type="text"
        id="first_name
          "
        name="first_name
          "
        className={`pro-input lg `}
        disabled
        value={profileState.data.rows.full_name}
      />
      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Courses*
        </label>
        <Select
          id="course_id"
          name="course_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.course_id && formik.touched.course_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData.data}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData.data?.filter(
            (m) => formik.values.course_id === m?.id
          )}
          onChange={(value) => handleCourseChange(value)}
        />
        {formik.touched.course_id && formik.errors.course_id && (
          <span className="error-text">{formik.errors.course_id}</span>
        )}
      </div>

      {/* {moduleList?.length !== 0 && formik.values.course_id ? (
        <>
          <p className="pro-font-sm pro-mb-1 pro-fw-medium">{`Modules*`}</p>

          {moduleList?.map((fields, index) => {
            return (
              <CourseModules
                key={index}
                formik={formik}
                fields={fields}
                fieldIndex={index}
                moduleList={moduleList}
                facultyList={facultyList}
              />
            );
          })}
        </>
      ) : (
        ""
      )} */}

      {/* <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Faculty*
        </label>
        <Select
          id="faculty_id"
          name="faculty_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.faculty_id && formik.touched.faculty_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={facultyData.data}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.user?.full_name}
          value={facultyData.data?.filter(
            (m) => formik.values.faculty_id === m?.id
          )}
          onChange={(value) => formik.setFieldValue("faculty_id", value.id)}
        />
        {formik.touched.faculty_id && formik.errors.faculty_id && (
          <span className="error-text">{formik.errors.faculty_id}</span>
        )}
      </div> */}

      <Input
        label={"Weekly Hours*"}
        id={`weekly_hours`}
        name={`weekly_hours`}
        type="text"
        placeholder={`In Hours`}
        {...formik.getFieldProps(`weekly_hours`)}
        className={`pro-input lg ${
          formik.touched.weekly_hours && formik.errors.weekly_hours && " error"
        }`}
        error={formik.touched.weekly_hours && formik.errors.weekly_hours}
        errorMessage={formik.errors.weekly_hours}
      />

      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Course Start Date*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={
              formik.values.course_start_date === ""
                ? "yyyy-MM-dd"
                : dateJoinStr
            }
            type={"date"}
            // max={maxDate.toLocaleDateString("fr-ca")}
            className={`pro-input lg ${
              formik.touched.course_start_date &&
              formik.errors.course_start_date &&
              "error"
            }`}
            onChange={handleJoinChange}
            onBlur={formik.handleBlur("course_start_date")}
            error={
              formik.touched.course_start_date &&
              formik.errors.course_start_date
            }
            errorMessage={formik.errors.course_start_date}
          />
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default CourseAssign;
