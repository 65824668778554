import React from "react";
import Style from "./timeTableCard.module.scss";

const TimeTableCard = ({ isBreak, slotData, handleClick }) => {
  return isBreak ? (
    <div
      className={`${Style.root_break} pro-d-flex pro-justify-center pro-items-center`}
    >
      <h6
        className={`h6 pro-ttl pro-mb-0 ${Style.title}`}
        style={{ color: "gray" }}
      >
        Slot Available
      </h6>
    </div>
  ) : (
    <div className={`${Style.root} pro-p-4`} onClick={handleClick}>
      {/* <h6 className={`h6 pro-ttl pro-mb-0 ${Style.title}`}>
        {slotData?.data[0]?.course_id ?? "Course Name"}
      </h6> */}
      <h6 className={`h6 pro-ttl pro-mb-0 ${Style.title}`}>
        {slotData?.data[0]?.course_module_name ?? "Course Module"}
      </h6>
      <p>
        {slotData?.data?.slice(0, 3)?.map((item, itemKey) => {
          if (
            item?.student_id !== "" &&
            item?.student_id !== undefined &&
            item?.student_id !== null
          ) {
            return (
              <span key={itemKey}>
                {item?.student_id}
                {slotData?.data?.length !== itemKey + 1 && (
                  <span>&nbsp;+&nbsp;</span>
                )}
                {slotData?.data?.length > 3 ? "..." : ""}
              </span>
            );
          }
        })}
      </p>
      <p>{`${slotData?.data?.[0]?.module_start_date ?? "DD-MM-YYYY"} to ${
        slotData?.data?.[0]?.module_end_date ?? "DD-MM-YYYY"
      }`}</p>
    </div>
  );
};

export default TimeTableCard;
