import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Global";

import { enquires } from "../../../../store/queries/enquires";
import { settings } from "../../../../store/queries/settings";
import { branches } from "../../../../store/queries/branches";
import { faculties } from "../../../../store/queries/faculties";
import { sales } from "../../../../store/queries/sales";
import { actionLogs } from "../../../../store/queries/actionLogs";
import { students } from "../../../../store/queries/students";
import { courses } from "../../../../store/queries/courses";
import { invoices } from "../../../../store/queries/invoices";
import { subAdmins } from "../../../../store/queries/subAdmins";
import { markNotificationRead } from "./api";
import {
  useGetNotificationListDataQuery,
  useUpdateNotificationDataMutation,
} from "../../../../store/queries/notifications";
import { getNotificationData } from "../../../../store/slices/Notifications/notificationSlice";
import { viewSubAdminProfile } from "../../../../store/slices/SubAdmins/subAdminsSlice";
import useRoutes from "../../../../routes/useRoutes";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const activeUser = useMemo(
    () => localStorage.getItem("USER_DETAILS") ?? [],
    []
  );

  const [updateNotification] = useUpdateNotificationDataMutation();
  const [page, setPage] = useState(1);

  const [isProfileViewVisible, setProfileViewVisible] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);
  const notificationState = useSelector((state) => state.notification);
  const subAdminState = useSelector((state) => state.subAdmins);
  const { allowedSettingsComponent } = useRoutes();

  useEffect(() => {
    if (isProfileViewVisible)
      dispatch(
        updateConfig((state) => {
          state.isProfileViewVisible = false;
          setProfileViewVisible(false);
        })
      );
  }, [isProfileViewVisible]);

  useEffect(() => {
    if (localStorage.getItem("USER_DETAILS") !== undefined)
      dispatch(viewSubAdminProfile());
    //eslint-disable-next-line
  }, []);

  const {
    data: notification = {},
    isFetching,
    // isLoading,
    refetch,
  } = useGetNotificationListDataQuery({
    page_size: notificationState.currentPageSize,
    page: notificationState.currentPage,
  });

  const handleReadNotification = (isNewNotification) => {
    if (isNewNotification)
      markNotificationRead().then((response) => {
        // setIsNewNotification(false);
      });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen((state) => !state);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchInputRef]);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  const handleViewProfile = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = true;
      })
    );
  };

  const handleCloseProfileView = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = false;
      })
    );
  };

  const handleLogout = () => {
    dispatch(enquires.util.resetApiState());
    dispatch(students.util.resetApiState());
    dispatch(courses.util.resetApiState());
    dispatch(invoices.util.resetApiState());
    dispatch(subAdmins.util.resetApiState());
    dispatch(branches.util.resetApiState());
    dispatch(settings.util.resetApiState());
    dispatch(faculties.util.resetApiState());
    dispatch(sales.util.resetApiState());
    dispatch(actionLogs.util.resetApiState());

    localStorage.removeItem("USER_DETAILS");
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("dashboard_permissions");

    navigate("/");
  };

  const handleSettings = () => {
    navigate(
      `/settings/${allowedSettingsComponent?.map((item) => item?.path)?.[0]}`
    );
  };

  // const isNewNotification = useMemo(() => {
  //   let notificationData = notification?.data?.rows?.data?.filter(
  //     (item) => item.read_status === 0
  //   );

  //   return notificationData?.length > 0;
  // }, [notification?.data]);

  useEffect(() => {
    let notificationData = notification?.data?.rows?.data?.filter(
      (item) => item.read_status === 0
    );

    if (notificationData?.length > 0) {
      dispatch(
        updateConfig((state) => {
          state.isNewNotification = true;
        })
      );
      // setIsNewNotification(true);
    }
  }, [notification?.data]);

  const handleProfileRedirect = (userId) => {
    // const ids = params.get("id")
    //   ? [...params.get("id").split(","), userId]
    //   : [userId];
    // const newIds = ids.filter((id) => id !== userId).join(",");
    // window.open(
    //   `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    // );
  };

  const handleNotificationRedirect = () => {
    // setIsNewNotification(false);
    // refetch?.();
    navigate("/notifications");

    // dispatch(
    //   getNotificationData({
    //     page: page,
    //     notification_id: "notificationData",
    //   })
    // );

    setTimeout(() => {
      updateNotification({ read_status: 1 }).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.isNewNotification = false;
            })
          );

          // dispatch(
          //   getNotificationData({
          //     page: page,
          //     notification_id: "notificationData",
          //   })
          // );
        }
      });
    }, 1000);
  };

  return {
    isModalOpen,
    searchInputRef,
    globalState,
    subAdminState,
    userData: globalState?.searchData?.[activeUser],
    setIsModalOpen,
    closeModal,
    handleLogout,
    handleProfileRedirect,
    handleSettings,
    handleViewProfile,
    handleCloseProfileView,
    setProfileViewVisible,
    notification: notification?.data,
    isNewNotification: globalState.isNewNotification,
    isFetching,
    handleReadNotification,
    handleNotificationRedirect,
  };
};

export default useHeaderActions;
