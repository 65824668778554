import React from "react";
import Style from "./selectWithIcons.module.scss";
import Select, { components } from "react-select";
import { Image } from "@wac-ui-dashboard/wac_component_library";

export const SelectWithIcons = ({
  options,
  onChange,
  multiSelect,
  label,
  data,
  dashboard,
  handleViewTooltip,
  handleHideTooltip,

  ...rest
}) => {
  const customMultiValueLabel = (option) => {
    return (
      <components.MultiValueLabel {...option}>
        <div
          className={`pro-d-flex ${Style.avatar_container} avatar-container`}
           onMouseLeave={!dashboard ? () => handleHideTooltip() : () => {}}
        >
      
          <div
            className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            onMouseEnter={
              !dashboard ? (e) => handleViewTooltip(e, data) : () => {}
            }
             onMouseLeave={!dashboard ? () => handleHideTooltip() : () => {}}
          >
            <Image
              src={option?.data?.image_url}
              width={16}
              height={16}
              alt={option?.data?.full_name}
            />
          </div>
          <span className="pro-ms-1 pro-align-self-center name">
            {option?.data?.full_name}
          </span>
        </div>
      </components.MultiValueLabel>
    );
  };

  const customOption = (option) => {
    return (
      <components.Option {...option}>
        <div className="pro-d-flex pro-items-center">
          <div className={`pro-avatar department-${option?.data?.value}`}>
            {option?.data?.icon}
          </div>
          <span className="pro-ps-1">{option?.data?.label}</span>
        </div>
      </components.Option>
    );
  };

  return (
    <Select
      {...rest}
      onChange={onChange}
      options={options}
      classNamePrefix="pro-input"
      className={`pro-input multi-select lg`}
      components={{
        Option: customOption,
        SingleValue: customMultiValueLabel,
      }}
      defaultValue={label}
    ></Select>
  );
};
