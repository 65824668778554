import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useEnroll from "./useEnroll";

const Enroll = () => {
  const { drawerMenu } = useEnroll();
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Enroll;
